import { call, put, select, takeLatest } from 'redux-saga/effects';
import apiActions from 'store/api/actions';
import userSelector from 'store/user/selectors';
import projectSelector from 'store/projects/selectors';
import { contractsConfig, ContractsNames } from 'config';
import { getProjectStatus } from '../actions';
import actionTypes from '../actionTypes';

export function* getProjectStatusSaga({
  type,
  payload: { web3Provider, onSuccess },
}: ReturnType<typeof getProjectStatus>) {
  yield put(apiActions.request(type));

  const { address, network, chainType } = yield select(userSelector.getUser);
  const { pid } = yield select(projectSelector.getProject('currentProject'));

  const { abi: totopadAbi, address: totopoadAddress } = contractsConfig.contracts[ContractsNames.totopad][chainType];

  try {
    const tokenContract = yield new web3Provider.eth.Contract(totopadAbi, totopoadAddress[network]);

    if (address) {
      const { isActive } = yield call(tokenContract.methods.projectInfo(pid).call);
      if (isActive) {
        onSuccess();
      }
    }

    yield put(apiActions.success(type));
  } catch (err) {
    console.log(err);

    yield put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_RPOJECT_STATUS, getProjectStatusSaga);
}
