import { useCallback, useMemo, VFC } from 'react';
import { Button } from 'components';
import userSelector from 'store/user/selectors';
import balanceSelector from 'store/balances/selectors';
import { useHeaderDropdownChange, useModal, useShallowSelector, useWindowState } from 'hooks';
import { BalancesState, Chains, HeaderControlsProps, Modals, State, UserState, WalletProviders } from 'types';
import { useDispatch } from 'react-redux';
import { setActiveModal } from 'store/modals/reducer';
import clsx from 'clsx';
import { StaticPart, LoginPart, FinishPart } from '../index';
import { networkitems } from './HeaderControls.helper';
import s from './styles.module.scss';

type IHeaderStatus = 'connectWallet' | 'finishPart' | 'loginPart';

export const HeaderControls: VFC<HeaderControlsProps> = ({ onConnectWallet }) => {
  const { address, key, isAdmin, network } = useShallowSelector<State, UserState>(userSelector.getUser);
  const { token } = useShallowSelector<State, BalancesState>(balanceSelector.getBalances);

  const [isNetworkModalVisible, handleNetworkOpen, handleNetworkClose] = useModal(false);
  const handleDropdownChange = useHeaderDropdownChange(handleNetworkOpen);

  const { width } = useWindowState();
  const isMobile = useMemo(() => +width < 1024, [width]);

  const dispatch = useDispatch();

  const handleOpenModal = useCallback(
    (type: Modals) => {
      dispatch(
        setActiveModal({
          activeModal: type,
          open: true,
        }),
      );
    },
    [dispatch],
  );

  const handleChangeNetwork = useCallback(
    (type: string) => {
      onConnectWallet(WalletProviders.metamask, Chains[type]);
    },
    [onConnectWallet],
  );

  const currIcon = useMemo(() => {
    return networkitems.items.find(({ title }) => title === network).icon;
  }, [network]);

  const headerStatusPart: IHeaderStatus = useMemo(() => {
    if (!address.length) {
      return 'connectWallet';
    }
    if (address.length && key.length) {
      return 'finishPart';
    }
    return 'loginPart';
  }, [address.length, key.length]);

  return (
    <div className={clsx(s.row, { [s.rowConnected]: address.length !== 0, [s.rowSigned]: key.length !== 0 })}>
      <StaticPart />

      {headerStatusPart === 'connectWallet' && (
        <div className={s.rowGridConnect}>
          <div className={s.itemBtn}>
            <Button onClick={() => handleOpenModal(Modals.ConnectWallet)} color="outline">
              Connect Wallet
            </Button>
          </div>
        </div>
      )}
      {headerStatusPart === 'loginPart' && (
        <LoginPart
          address={address}
          isAdmin={isAdmin}
          userKey={key}
          network={network}
          tokenBalance={token}
          isMobile={isMobile}
          currIcon={currIcon}
          isNetworkModalVisible={isNetworkModalVisible}
          handleNetworkOpen={handleNetworkOpen}
          handleNetworkClose={handleNetworkClose}
          onOpenModal={handleOpenModal}
          onDropDownChange={handleDropdownChange}
          onChangeNetwork={handleChangeNetwork}
        />
      )}
      {headerStatusPart === 'finishPart' && (
        <FinishPart
          address={address}
          isAdmin={isAdmin}
          userKey={key}
          network={network}
          tokenBalance={token}
          isMobile={isMobile}
          currIcon={currIcon}
          isNetworkModalVisible={isNetworkModalVisible}
          handleNetworkOpen={handleNetworkOpen}
          handleNetworkClose={handleNetworkClose}
          onOpenModal={handleOpenModal}
          onDropDownChange={handleDropdownChange}
          onChangeNetwork={handleChangeNetwork}
        />
      )}
    </div>
  );
};
