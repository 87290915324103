import React from 'react';

import clsx from 'clsx';
import RcCheckbox, { Props } from 'rc-checkbox';
import { Typography } from 'components/Typography';

import 'rc-checkbox/assets/index.css';
import 'styles/components/checkbox.scss';

export interface CheckBoxProps extends Props {
  text: string | React.ReactElement;
  textClassName?: string;
  className?: string;
}

export const CheckBox: React.FC<CheckBoxProps> = ({ text, textClassName, className, ...other }) => {
  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label className={clsx('checkboxLabel', className)}>
      {/* @ts-ignore */}
      <RcCheckbox {...other} prefixCls="checkbox" />
      <Typography size="f16" className={clsx('checkboxText', textClassName)}>
        {text}
      </Typography>
    </label>
  );
};
