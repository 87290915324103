/* eslint-disable @typescript-eslint/no-explicit-any */
import { call, put, select, takeLatest } from 'redux-saga/effects';
import apiActions from 'store/api/actions';
import userSelector from 'store/user/selectors';
import { contractsConfig, ContractsNames } from 'config';
import { getTokenAmount, getTokenAmountParts } from 'utils';
import { toast } from 'react-toastify';
import { notifyText } from 'config/constants';
import { erc20Abi } from 'config/abi';
import { approveSaga } from '../../user/sagas/approveSaga';
import actionTypes from '../actionTypes';
import userActionTypes from '../../user/actionTypes';
import { signingProject } from '../actions';
import { createProjectSaga } from './createProjectSaga';

export function* signingProjectSaga({
  type,
  payload: { web3Provider, data, onSuccess },
}: ReturnType<typeof signingProject>) {
  yield put(apiActions.request(type));
  const myAddress = yield select(userSelector.getProp('address'));
  const network = yield select(userSelector.getProp('network'));
  const chainType = yield select(userSelector.getProp('chainType'));

  const {
    tokenContractAddress,
    walletAddress,
    allocStartTime,
    stakingEndTime,
    fundingEndTime,
    rewardStopTime,
    rewardsTokenAmount,
    targetRace,
    softCap,
    projectName,
  } = data;

  const { abi: tokenAbi, address: tokenAddress } = contractsConfig.contracts[ContractsNames.totopad][chainType];
  const { address: fundingTokenAddress } = contractsConfig.contracts[ContractsNames.fundingToken][chainType];

  const allocStartTimestamp = new Date(allocStartTime).getTime() / 1000;
  const stakingEndTimestamp = new Date(stakingEndTime).getTime() / 1000;
  const fundingEndTimestamp = new Date(fundingEndTime).getTime() / 1000;
  const rewardStopTimestamp = new Date(rewardStopTime).getTime() / 1000;

  try {
    const tokenContract = yield new web3Provider.eth.Contract(tokenAbi, tokenAddress[network]);
    const rewardTokenContract = yield new web3Provider.eth.Contract(erc20Abi as any, tokenContractAddress);
    const fundingTokenContract = yield new web3Provider.eth.Contract(erc20Abi as any, fundingTokenAddress[network]);

    const decimalsRewardToken = yield call(rewardTokenContract.methods.decimals().call);
    const decimalsFundingToken = yield call(fundingTokenContract.methods.decimals().call);

    const rewardAmountArr = getTokenAmountParts(rewardsTokenAmount, decimalsRewardToken);
    const targetRaceAmountArr = getTokenAmountParts(targetRace, decimalsFundingToken);
    const softCapWithDecimals = getTokenAmount(softCap, decimalsFundingToken);

    if (myAddress) {
      yield call(approveSaga, {
        type: userActionTypes.APPROVE,
        payload: {
          web3Provider,
          spender: tokenContractAddress,
          amount: rewardsTokenAmount,
          decimals: decimalsRewardToken,
          tokenAddress: tokenAddress[network],
        },
      });

      const { transactionHash } = yield call(
        tokenContract.methods.add(
          tokenContractAddress,
          walletAddress,
          allocStartTimestamp,
          stakingEndTimestamp,
          fundingEndTimestamp,
          rewardStopTimestamp,
          rewardAmountArr,
          targetRaceAmountArr,
          softCapWithDecimals,
        ).send,
        {
          from: myAddress,
          to: tokenAddress[network],
        },
      );

      yield call(createProjectSaga, {
        type: actionTypes.CREATE_PROJECT,
        payload: {
          data: {
            ...data,
            txHash: transactionHash,
          },
          onSuccess,
        },
      });
    }

    // onSuccess();
    toast.success(notifyText.approveProject.success(projectName));
    yield put(apiActions.success(type));
  } catch (err) {
    console.log(err);
    toast.error(notifyText.approveProject.error);
    yield put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.SIGNING_PROJECT, signingProjectSaga);
}
