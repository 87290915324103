import { useMemo, VFC } from 'react';

import { NavLink, useLocation } from 'react-router-dom';
import projectsSelector from 'store/projects/selectors';
import balancesSelector from 'store/balances/selectors';
import { Typography } from 'components';
import { arrowRight, home } from 'assets/img/section';
import { routes } from 'appConstants/routes';
import { getRouteTitle } from 'utils';
import { useShallowSelector } from 'hooks';
import { BalancesState, ProjectsState, State } from 'types';
import { navItems } from './Header.helper';
import s from './styles.module.scss';

export const HeaderNavs: VFC = () => {
  const { pathname } = useLocation();
  const { totalFilterProjects: projects } = useShallowSelector<State, ProjectsState>(projectsSelector.getAllProjects);
  const { token } = useShallowSelector<State, BalancesState>(balancesSelector.getBalances);

  const NavItemsHTML = useMemo(() => {
    const isSpecialPage =
      pathname === '/signup' ||
      pathname === '/announcements' ||
      pathname.includes('account') ||
      pathname.includes('project');

    const firstNavPath = pathname.includes('account') ? routes.account.details.root : routes.root;
    const secondNavPath = pathname.includes('account') ? routes.account.projects.root : pathname;
    const firstNavTitle = isSpecialPage ? routes.title : routes.account.details.title;
    const secondNavTitle = pathname.includes('account') ? routes.account.projects.title : getRouteTitle(pathname);

    if (isSpecialPage) {
      return (
        <div className={s.signup}>
          {(pathname === '/signup' || pathname === '/announcements' || pathname.includes('project')) && (
            <img src={home} alt="home" />
          )}
          <NavLink className={({ isActive }) => (isActive && s.active) || s.notActive} to={firstNavPath}>
            <Typography color="white" type="span" size="f16">
              {firstNavTitle}
            </Typography>
          </NavLink>
          <img src={arrowRight} alt="arrow" />
          <NavLink className={({ isActive }) => (isActive && s.active) || s.notActive} to={secondNavPath}>
            <Typography color="white" type="span" size="f16">
              {secondNavTitle}
            </Typography>
          </NavLink>
        </div>
      );
    }

    return (
      <ul className={s.itemNav}>
        {navItems.map(({ name, link }, index) => (
          <li key={name + link}>
            <NavLink className={({ isActive }) => (isActive && s.active) || s.notActive} to={link}>
              <Typography color="white" type="span" size="f16">
                {name}
              </Typography>
              <div className={s.totalValue}>{!!projects.length && projects[index].total}</div>
              {/* <div className={s.totalValue}>{(!!projects.length && projects[index].total) || '...'}</div> */}
            </NavLink>
          </li>
        ))}
      </ul>
    );
  }, [pathname, projects]);

  return (
    <div className={s.row}>
      {NavItemsHTML}

      <div className={s.itemBalance}>
        {token !== 0 && (
          <>
            <Typography type="span" color="white" size="f12" uppercase>
              Balance:
            </Typography>
            <Typography color="red" size="f18" className={s.itemBalanceText}>
              <Typography type="span" color="red" size="f18">
                {token}
              </Typography>
              <Typography type="span" color="red" size="f18">
                TOTO
              </Typography>
            </Typography>
          </>
        )}
      </div>
    </div>
  );
};
