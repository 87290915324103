/* eslint-disable @typescript-eslint/no-explicit-any */
import { VFC } from 'react';
import { Button, Typography } from 'components';
import { ITabProps } from '../PoolFromCard.types';
import s from '../styles.module.scss';

export const ClaimingTab: VFC<ITabProps> = ({ isLoading, balance, onWithdraw, onClaim }) => {
  const { fundingBalance, softCapRecruited, haveRewards, userStaked } = balance;
  console.table([
    ['fundingBalance:', fundingBalance],
    ['softCapRecruited:', softCapRecruited],
    ['haveRewards:', haveRewards],
    ['userStaked:', userStaked],
  ]);

  return (
    <ul className={s.tab}>
      <li>
        {softCapRecruited && +fundingBalance !== 0 && haveRewards && (
          <>
            <Typography type="h3" size="f20">
              Claim your rewards
            </Typography>
            <p>
              Your rewards will be unlocked in accordance with the projects vesine schedule. If vou require support
              please go to ou official &nbsp;
              <strong>Telegram Support</strong>
            </p>
            <Button color="outline-green" isLoading={isLoading} onClick={onClaim} className={s.fundBtn}>
              Claim Rewards
            </Button>
          </>
        )}
        {softCapRecruited && +fundingBalance !== 0 && !haveRewards && (
          <>
            <Typography type="h3" size="f20">
              Claim rewards
            </Typography>
            <p>Congratulations! You have successfully claimed your rewards!</p>
          </>
        )}

        {+userStaked !== 0 && (+fundingBalance === 0 || !softCapRecruited) && (
          <>
            <Typography type="h3" size="f20">
              Refund
            </Typography>
            <p>
              We’ve detected that you didn’t redeem your allocation at Funding phase. Now you can claim back your TOTO
              tokens that you have staked earlie
              <strong>Telegram Support</strong>
              <Button color="outline-green" isLoading={isLoading} onClick={onWithdraw} className={s.fundBtn}>
                Withdraw
              </Button>
            </p>
          </>
        )}
        {+userStaked === 0 && +fundingBalance === 0 && !haveRewards && (
          <p>You have missed the Staking phase of this project. DYOR to get info about this project</p>
        )}
      </li>
    </ul>
  );
};
