import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PostsState } from 'types';

const initialState: PostsState = {
  posts: [],
};

export const postsReducer = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    updatePostsState: (state, action: PayloadAction<Partial<PostsState>>) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const { updatePostsState } = postsReducer.actions;

export default postsReducer.reducer;
