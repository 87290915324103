// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getEncodeMedia = async (file: any) => {
  return new Promise((res) => {
    const dataForEncode = new FileReader();
    dataForEncode.readAsDataURL(file);
    dataForEncode.onload = (e) => {
      const { result } = e.target;
      res(result as string);
    };
  });
};
