export default {
  metamaskSignUp: 'auth/users/register/',
  metamaskSignIn: 'auth/users/login/',
  resetPassword: '/auth/users/forgot-password/',
  updateUserInfo: '/auth/users/me/',
  getUserInfo: '/auth/users/me/',
  createProject: '/ido/create/',
  getUserProjects: (address: string) => `/ido/ido/user/?address=${address}`,
  getCurrentProject: (id: string) => `/ido/${id}/`,
  getAllProjects: (url: string) => `/ido/?${url}`,
  // adminApprove: (id: string) => `/ido/${id}/approve/`,
  // adminReject: (id: string) => `/ido/${id}/reject/`,
  createPost: '/blog/create/',
  deletePost: (id: number) => `/blog/delete/${id}`,
  getPosts: '/blog/',
};
