export const poolSwitcherItems = [
  {
    value: 0,
    text: '250 TOTO Limit',
  },
  {
    value: 1,
    text: '1000 TOTO Limit',
  },
  {
    value: 2,
    text: 'No limit',
  },
];
