import { useMemo, VFC } from 'react';
// import { bell } from 'assets/img/section';
import { AccountParams, CurrentNetwork, Modal, Select } from 'components';
import { NetworkSelect } from 'containers';
import { IControlsPartProps } from 'types';
import { networkitems, selectOptionItems } from '../HeaderControls.helper';
import s from '../styles.module.scss';

export const FinishPart: VFC<IControlsPartProps> = ({
  address,
  userKey,
  isMobile,
  tokenBalance,
  currIcon,
  network,
  isAdmin,
  isNetworkModalVisible,
  handleNetworkOpen,
  handleNetworkClose,
  onDropDownChange,
  onChangeNetwork,
}) => {
  const options = useMemo(() => {
    if (isMobile) {
      return selectOptionItems.mobileOptions(!!userKey, tokenBalance, {
        onOpen: handleNetworkOpen,
        network,
        currIcon,
      });
    }
    return selectOptionItems.desktopOptions(isAdmin);
  }, [currIcon, handleNetworkOpen, isAdmin, isMobile, network, tokenBalance, userKey]);

  return (
    <div className={s.rowGridFinish}>
      <CurrentNetwork onOpen={handleNetworkOpen} currIcon={currIcon} network={network} className={s.item} />
      <AccountParams address={address} className={s.item} />

      <div className={s.item}>
        <Select
          allowToChange={false}
          options={options}
          defaultOption={selectOptionItems.defaultOption}
          onSelect={onDropDownChange}
        />
      </div>

      {isNetworkModalVisible && (
        <Modal
          open={isNetworkModalVisible}
          onClose={handleNetworkClose}
          className={s.modal}
          wrapClassName={s.modalWrapper}
          closable
          closeIcon={false}
        >
          <NetworkSelect data={networkitems} closeModal={handleNetworkClose} onChangeNetwork={onChangeNetwork} />
        </Modal>
      )}
    </div>
  );
};
