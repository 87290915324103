/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useMemo, useState, VFC } from 'react';
import cn from 'clsx';
import { Button, CardBox, Switcher, Typography } from 'components';
import { useDispatch } from 'react-redux';
import { claimReward, fund, onWithdrawOwner, stake, withdraw } from 'store/projects/actions';
import uiSelector from 'store/ui/selectors';
import projectsActionTypes from 'store/projects/actionTypes';
import { useWalletConnectorContext } from 'services';
import { useShallowSelector } from 'hooks';
import { BalancesState, OptionProps, RequestStatus, State } from 'types';
import { getBalancesInfo } from 'store/balances/actions';
import balancesSelector from 'store/balances/selectors';
import { StakingTab } from './StakingTab';
import { FundingTab } from './FundingTab';
import { poolSwitcherItems } from './PoolFormCard.helper';
import { SkeletonPoolForm } from '../Skeletons';
import s from './styles.module.scss';
import { ClaimingTab } from './ClaimingTab';

export interface PoolFormCardProps {
  className?: string;
  pid: number;
  onChangeLimit: (option: OptionProps) => void;
  activeTab: OptionProps;
  balance: BalancesState;
  isBalancesLoading: boolean;
  address: string;
  currentProject: any;
  network: string;
}

export const PoolFormCard: VFC<PoolFormCardProps> = ({
  className,
  address,
  pid,
  onChangeLimit,
  activeTab,
  balance,
  isBalancesLoading,
  currentProject,
  network,
}) => {
  const {
    rewardInfo: { totalRaised, softCap, rewardAmount },
  } = useShallowSelector<State, BalancesState>(balancesSelector.getBalances);

  const { walletService } = useWalletConnectorContext();
  const [stakeAmount, setStakeAmount] = useState('');
  const [isCanOwnerWithdraw, setCanOwnerWithdraw] = useState(false);

  const dispatch = useDispatch();

  const {
    [projectsActionTypes.STAKE]: stakeRequest,
    [projectsActionTypes.WITHDRAW]: withdrawRequest,
    [projectsActionTypes.WITHDRAW_OWNER]: ownerWithdrawRequest,
    [projectsActionTypes.FUND]: fundRequest,
    [projectsActionTypes.CLAIM_REWARD]: claimRequest,
    [projectsActionTypes.GET_CURRENT_PROJECT]: gettingProjectDataRequest,
  } = useShallowSelector(uiSelector.getUI);

  const isStaking = useMemo(() => stakeRequest === RequestStatus.REQUEST, [stakeRequest]);
  const isWithdrawing = useMemo(() => withdrawRequest === RequestStatus.REQUEST, [withdrawRequest]);
  const isOwnerWithdrawing = useMemo(() => ownerWithdrawRequest === RequestStatus.REQUEST, [ownerWithdrawRequest]);
  const isFunding = useMemo(() => fundRequest === RequestStatus.REQUEST, [fundRequest]);
  const isClaiming = useMemo(() => claimRequest === RequestStatus.REQUEST, [claimRequest]);
  const isStaked = useMemo(() => stakeRequest === RequestStatus.SUCCESS, [stakeRequest]);
  const isProjectDataFetched = useMemo(
    () => gettingProjectDataRequest === RequestStatus.SUCCESS,
    [gettingProjectDataRequest],
  );

  const handleChangeLimit = useCallback(
    (option: OptionProps) => {
      onChangeLimit(option);
      setStakeAmount('0');
      dispatch(
        getBalancesInfo({
          web3Provider: walletService.Web3(),
          activeLimit: activeTab.value,
        }),
      );
    },
    [activeTab.value, dispatch, onChangeLimit, walletService],
  );

  const handleChangeStakeValue = useCallback((value: string) => {
    setStakeAmount(value);
  }, []);

  const handleStake = useCallback(() => {
    dispatch(
      stake({
        web3Provider: walletService.Web3(),
        pid,
        amount: stakeAmount,
        activeTab: activeTab.value,
      }),
    );
  }, [activeTab, pid, dispatch, stakeAmount, walletService]);

  const handleFund = useCallback(() => {
    dispatch(
      fund({
        web3Provider: walletService.Web3(),
        pid,
        activeTab: activeTab.value,
      }),
    );
  }, [dispatch, walletService, pid, activeTab.value]);

  const handleWithdraw = useCallback(() => {
    dispatch(
      withdraw({
        web3Provider: walletService.Web3(),
        pid,
        activeTab: activeTab.value,
      }),
    );
  }, [dispatch, walletService, pid, activeTab.value]);

  const handleClaim = useCallback(() => {
    dispatch(
      claimReward({
        web3Provider: walletService.Web3(),
        pid,
        activeTab: activeTab.value,
      }),
    );
  }, [dispatch, walletService, pid, activeTab.value]);

  const handleCheckIsOwner = useCallback(() => {
    const isOwner =
      address?.length &&
      currentProject?.walletAddress?.length &&
      address.toLocaleLowerCase() === currentProject.walletAddress.toLowerCase();

    const isDestributionStage = new Date().getTime() / 1000 > currentProject.rewardStopTime;

    if (isOwner && isDestributionStage && +rewardAmount !== 0) {
      setCanOwnerWithdraw(true);
    } else {
      setCanOwnerWithdraw(false);
    }
  }, [address, currentProject.rewardStopTime, currentProject.walletAddress, rewardAmount]);

  const handleOwnerWithdraw = useCallback(() => {
    dispatch(
      onWithdrawOwner({
        web3Provider: walletService.Web3(),
        pid: currentProject.pid.toString(),
        isSoftCapRecruited: +totalRaised >= +softCap,
      }),
    );
  }, [currentProject.pid, dispatch, softCap, totalRaised, walletService]);

  const TabContent = useMemo(() => {
    if (isBalancesLoading || !isProjectDataFetched) {
      return SkeletonPoolForm;
    }
    if (currentProject.stage === 'staking') {
      return StakingTab;
    }
    if (currentProject.stage === 'funding') {
      return FundingTab;
    }
    return ClaimingTab;
  }, [currentProject, isBalancesLoading, isProjectDataFetched]);

  useEffect(() => {
    if (isStaked) {
      setStakeAmount('');
    }
  }, [isStaked]);

  useEffect(() => {
    if (address.length && isProjectDataFetched) {
      dispatch(
        getBalancesInfo({
          web3Provider: walletService.Web3(),
          activeLimit: activeTab.value,
        }),
      );
    }
  }, [activeTab.value, address, dispatch, isProjectDataFetched, walletService]);

  useEffect(() => {
    handleCheckIsOwner();
  }, [handleCheckIsOwner]);

  return (
    <div className={cn(s.poolFormCard, className)}>
      <Typography size="f20" className={s.title}>
        Select a pool to view
      </Typography>

      <CardBox className={s.container}>
        <Switcher option={poolSwitcherItems} active={activeTab} onChangeActive={handleChangeLimit} />
        <TabContent
          handleChange={handleChangeStakeValue}
          onStake={handleStake}
          onFund={handleFund}
          onWithdraw={handleWithdraw}
          onClaim={handleClaim}
          isLoading={isStaking || isFunding || isClaiming || isWithdrawing}
          value={stakeAmount}
          balance={balance}
          currentProject={currentProject}
          network={network}
          currentLimit={activeTab}
        />
      </CardBox>
      {isCanOwnerWithdraw && rewardAmount !== 0 && (
        <Button color="filled" className={s.claimBtn} onClick={handleOwnerWithdraw} isLoading={isOwnerWithdrawing}>
          Claim Funds
        </Button>
      )}
    </div>
  );
};
