import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { BalancesState } from 'types';

export const initialState: BalancesState = {
  token: 0,
  totalStaked: 0,
  userStaked: 0,
  stakedSoFar: 0,
  fundingBalance: 0,
  haveFundation: false,
  softCapRecruited: false,
  haveRewards: false,
  rewardInfo: {
    totalRaised: 0,
    softCap: 0,
    rewardAmount: 0,
  },
};

export const balancesReducer = createSlice({
  name: 'balances',
  initialState,
  reducers: {
    updateBalancesState: (state, action: PayloadAction<Partial<BalancesState>>) => ({
      ...state,
      ...action.payload,
    }),
    clearBalanceState: () => ({
      ...initialState,
    }),
  },
});

export const { clearBalanceState, updateBalancesState } = balancesReducer.actions;

export default balancesReducer.reducer;
