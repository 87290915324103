import { VFC } from 'react';

import clsx from 'clsx';

import { CardBox, Typography } from 'components';
import { infoLarge } from 'assets/img/section';
import s from './styles.module.scss';

export interface NoProjectsCardProps {
  className?: string;
  size?: 'sm' | 'lg';
}

export const NoProjectsCard: VFC<NoProjectsCardProps> = ({ className, size = 'lg' }) => {
  return (
    <CardBox className={clsx(s.notFound, className, s[size])}>
      <img src={infoLarge} alt="info" />
      <Typography color="gray" size="f28">
        No Projects
      </Typography>
    </CardBox>
  );
};
