import { useMemo, VFC } from 'react';
import cn from 'clsx';
import { CardBox, FormBorder, StepsProgress, Typography } from 'components';
import { totalStaked } from 'assets/img/section';
import { BalancesState, IStageProps } from 'types';
import { projectPhases } from './ProgressCard.helper';
import { SkeletonProgressCard } from '../Skeletons';
import s from './styles.module.scss';

export interface ProgressCardProps {
  className?: string;
  stage: IStageProps;
  balance: BalancesState;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currentProject: any;
  isBalanceLoading: boolean;
}

export const ProgressCard: VFC<ProgressCardProps> = ({ className, stage, balance, isBalanceLoading }) => {
  const currentStage = useMemo(() => {
    switch (stage) {
      case 'staking':
        return 1;
      case 'funding':
        return 2;
      case 'distributing':
        return 3;
      case 'completed':
        return 3;
      default:
        return 1;
    }
  }, [stage]);

  return (
    <div className={cn(s.progressCard, className)}>
      <Typography type="span" size="f20">
        Project Progress
      </Typography>
      <FormBorder className={s.border} />

      {isBalanceLoading ? (
        <SkeletonProgressCard />
      ) : (
        <CardBox className={s.container}>
          <div className={s.top}>
            <div className={s.totalStaked}>
              <Typography size="f12" color="gray" uppercase className={s.subtitle}>
                Staked so far
              </Typography>
              <Typography color="red" size="f18">
                <img src={totalStaked} alt="icon" />
                {balance.stakedSoFar} TOTO
              </Typography>
            </div>
            {/* <img src={swapArrows} alt="swap" />
            <div className={s.dollars}>
              <Typography size="f18">
                <img src={dollar} alt="dollar" />
                {(balance.stakedSoFar * tokenPrice).toFixed(3)}
              </Typography>
            </div> */}
          </div>

          <StepsProgress
            size="circle"
            options={projectPhases}
            active={currentStage}
            wide
            title="Current Phase"
            className={s.progress}
          />

          <Typography type="span" size="f14" color="gray">
            This projects rewards are now distributing. If you funded this project take a look below to see how you can
            claim your rewards.
          </Typography>
        </CardBox>
      )}
    </div>
  );
};
