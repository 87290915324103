import { Button, Typography, CardBox } from 'components';
import { useCallback, VFC } from 'react';

import s from './styles.module.scss';

export interface NetworkSelectProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  closeModal: () => void;
  onChangeNetwork: (type: string) => void;
}

export const NetworkSelect: VFC<NetworkSelectProps> = ({ data, closeModal, onChangeNetwork }) => {
  const handleChangeNetwork = useCallback(
    (type: string) => {
      onChangeNetwork(type);
      closeModal();
    },
    [closeModal, onChangeNetwork],
  );

  return (
    <CardBox className={s.container}>
      <Typography type="span" size="f18">
        {data?.title}
      </Typography>
      <ul>
        {data?.items.map(({ title, icon, id, type }) => (
          <li key={id}>
            <Button icon={icon} size="lg" color="outline" onClick={() => handleChangeNetwork(type)} className={s.btn}>
              {title}
            </Button>
          </li>
        ))}
      </ul>
    </CardBox>
  );
};
