import { call, put, select, takeLatest } from 'redux-saga/effects';
import apiActions from 'store/api/actions';
import projectSelector from 'store/projects/selectors';
import { baseApi } from 'store/api/apiRequestBuilder';
import { getAllProjects } from '../actions';
import actionTypes from '../actionTypes';
import { updateProjectsState } from '../reducer';

export function* getAllProjectsSaga({ type, payload: { url } }: ReturnType<typeof getAllProjects>) {
  yield put(apiActions.request(type));

  const projects = yield select(projectSelector.getProject('allProjects'));
  try {
    const {
      data: { items, total },
    } = yield call(baseApi.getAllProjects, url);

    if (items.length) {
      yield put(updateProjectsState({ allProjects: [...projects, ...items], totalOnCurrFilter: total }));
    } else {
      yield put(updateProjectsState({ allProjects: [], totalOnCurrFilter: 0 }));
    }

    yield put(apiActions.success(type));
  } catch (err) {
    console.log(err);
    yield put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_ALL_PROJECTS, getAllProjectsSaga);
}
