import { put, takeLatest, call, select } from 'redux-saga/effects';
import apiActions from 'store/api/actions';
import { messageGenerator } from 'utils';
import { baseApi } from 'store/api/apiRequestBuilder';
import userSelector from 'store/user/selectors';
import { setActiveModal } from 'store/modals/reducer';
import { Modals } from 'types';
import actionTypes from '../actionTypes';
import { signUp } from '../actions';
import { updateUserState } from '../reducer';

export function* signUpSaga({
  type,
  payload: { email, password, web3Provider },
}: ReturnType<typeof signUp>) {
  yield put(apiActions.request(type));
  try {
    const address = yield select(userSelector.getProp('address'));
    const metamaskMessage = messageGenerator(26);
    const signedMessage = yield call(web3Provider.eth.personal.sign, metamaskMessage, address, '');

    yield call(baseApi.metamaskSignUp, {
      address,
      email,
      password,
      message: metamaskMessage,
      signature: signedMessage,
    });

    yield put(
      updateUserState({
        account: {
          email,
          password,
        },
      }),
    );
    yield put(
      setActiveModal({
        activeModal: Modals.LoginAndAuth,
        txHash: '',
        open: true,
      }),
    );
    yield put(apiActions.success(type));
  } catch (err) {
    console.log(err);
    yield put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.SIGN_UP, signUpSaga);
}
