import { useEffect, useState, VFC } from 'react';
import { Typography } from 'components/Typography';
import { Button } from 'components/Button';
import { copy } from 'assets/img/section';
import cn from 'clsx';

import s from './styles.module.scss';

export interface CopyAddressFieldProps {
  className?: string;
  address: string;
}

export const CopyAddressField: VFC<CopyAddressFieldProps> = ({ className, address }) => {
  const [copyText, setCopyText] = useState('');

  useEffect(() => {
    const timer = setTimeout(() => {
      setCopyText('');
    }, 1000);
    return () => clearTimeout(timer);
  }, [copyText]);

  const handleCopyAddress = async () => {
    try {
      await navigator.clipboard.writeText(address);
      setCopyText('Coppied!');
    } catch (err) {
        setCopyText('Not coppied!');
    }
  };
  return (
    <Button onClick={handleCopyAddress} className={cn(s.copyAddress, s.btn, className)} color="default">
      {copyText ? (
        <Typography color="gray-dark">{copyText}</Typography>
        ) : (
          <>
            <Typography color="gray-dark" className={s.addressText}>
              {address}
            </Typography>
            <img src={copy} alt="copy" />
          </>
       )}
    </Button>
  );
};
