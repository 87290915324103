import { VFC } from 'react';

import clsx from 'clsx';

import { CardBox, SkeletonLoader } from 'components';
import s from './styles.module.scss';

export interface SkeletonCardProps {
  className?: string;
}

export const SkeletonCard: VFC<SkeletonCardProps> = ({ className }) => {
  const WIDTH_ROW = '70px';
  return (
    <CardBox className={clsx(s.card, className)}>
      <div className={s.container}>
        <div className={s.title}>
          <SkeletonLoader width="120px" />
        </div>
        <div className={s.row}>
          <SkeletonLoader width={WIDTH_ROW} />
          <SkeletonLoader width={WIDTH_ROW} />
        </div>
        <div className={s.row}>
          <SkeletonLoader width={WIDTH_ROW} />
          <SkeletonLoader width={WIDTH_ROW} />
        </div>
        <div className={s.row}>
          <SkeletonLoader width={WIDTH_ROW} />
          <SkeletonLoader width={WIDTH_ROW} />
        </div>
      </div>
      <div className={s.bottom}>
        <SkeletonLoader width={WIDTH_ROW} />
      </div>
    </CardBox>
  );
};
