import { fork } from 'redux-saga/effects';

import signIn from './signInSaga';
import signUp from './signUpSaga';
import resetPassword from './resetPasswordSaga';
import updateUserInfo from './updateUserInfoSaga';
import getUserInfo from './getUserInfoSaga';
import approve from './approveSaga';

export default function* userSagas() {
  yield fork(signUp);
  yield fork(signIn);
  yield fork(resetPassword);
  yield fork(updateUserInfo);
  yield fork(getUserInfo);
  yield fork(approve);
}
