// import { inputError } from 'assets/img/section';
import clsx from 'clsx';
import { Input, IInput } from 'components';
import { TextArea } from 'components/TextArea';
import { Typography } from 'components/Typography';
import { ReactElement } from 'react';

import s from './styles.module.scss';

interface IInputColor {
  color?: 'gray-dark' | 'gray-light' | 'transparent';
}

interface IInputSize {
  size?: 'sm' | 'md' | 'lg';
}

export interface InputWrapperProps extends IInputColor, IInput, IInputSize {
  title?: string;
  prefix?: ReactElement;
  postfix?: ReactElement;
  component?: 'input' | 'textArea';
  error?: string;
  subtitle?: string;
  className?: string;
  disabled?: boolean;
  id: string;
}

export const InputWrapper: React.FC<InputWrapperProps> = ({
  title,
  prefix,
  postfix,
  component = 'input',
  color = 'gray-dark',
  size = 'md',
  error,
  subtitle,
  className,
  id,
  ...other
}) => {
  return (
    <label htmlFor={id} className={clsx(s.wrapper, className)}>
      {title && (
        <Typography type="div" color="white" size="f12" uppercase className={s.title}>
          {title}
        </Typography>
      )}

      <div
        className={clsx(s.content, s[`content_${color}`], s[size], {
          [s.errorBorder]: error,
          [s.textArea]: component === 'textArea',
        })}
      >
        {prefix && <div className={s.prefix}>{prefix}</div>}

        {component === 'input' && <Input id={id} {...other} />}
        {component === 'textArea' && <TextArea id={id} {...other} />}

        {postfix && <div className={s.postfix}>{postfix}</div>}
      </div>
      {error && (
        <Typography color="orange" size="f12" className={s.error}>
          {error}
        </Typography>
      )}

      {subtitle && (
        <Typography type="div" color="white" size="f12" uppercase className={s.subtitle}>
          {subtitle}
        </Typography>
      )}
    </label>
  );
};
