import { routes } from 'appConstants/routes';

export const navItems: { name: string; link: string }[] = [
  {
    name: 'All projects',
    link: routes.root,
  },
  {
    name: 'Staking',
    link: routes.projects.all.getUrl('staking'),
  },
  {
    name: 'Funding',
    link: routes.projects.all.getUrl('funding'),
  },
  {
    name: 'Distributing',
    link: routes.projects.all.getUrl('distributing'),
  },
  {
    name: 'Completed',
    link: routes.projects.all.getUrl('completed'),
  },
];
