import { call, put, select, takeLatest } from 'redux-saga/effects';
import apiActions from 'store/api/actions';
import userSelector from 'store/user/selectors';
import { contractsConfig, ContractsNames } from 'config';
import { toast } from 'react-toastify';
import { notifyText } from 'config/constants';
import { getBalanceInfoSaga } from 'store/balances/sagas/getBalanceInfoSaga';
import balanceActionTypes from 'store/balances/actionTypes';
import actionTypes from '../actionTypes';
import { withdraw } from '../actions';

export function* withdrawSaga({ type, payload: { web3Provider, pid, activeTab } }: ReturnType<typeof withdraw>) {
  yield put(apiActions.request(type));
  const myAddress = yield select(userSelector.getProp('address'));
  const network = yield select(userSelector.getProp('network'));
  const chainType = yield select(userSelector.getProp('chainType'));
  const activePidValue = pid * 3 + activeTab;

  const { abi: totopadAbi, address: stakingAddress } = contractsConfig.contracts[ContractsNames.totopad][chainType];

  try {
    const totopadContract = yield new web3Provider.eth.Contract(totopadAbi, stakingAddress[network]);

    if (myAddress) {
      yield call(totopadContract.methods.withdraw(activePidValue).send, {
        from: myAddress,
        to: stakingAddress[network],
      });
      yield call(getBalanceInfoSaga, {
        type: balanceActionTypes.GET_BALANCES_INFO,
        payload: {
          web3Provider,
          activeLimit: activeTab,
        },
      });
    }

    toast.success(notifyText.withdraw.success);
    yield put(apiActions.success(type));
  } catch (err) {
    console.log(err);
    toast.error(notifyText.withdraw.error);
    yield put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.WITHDRAW, withdrawSaga);
}
