/* eslint-disable @typescript-eslint/no-explicit-any */
import { VFC } from 'react';

import clsx from 'clsx';

import { Button } from 'components/Button';
import { Typography } from 'components/Typography';
import { checkGray, checkGreen, checkRed } from 'assets/img/section';
import s from './styles.module.scss';

export interface StepsProgressProps {
  className?: string;
  color?: 'red' | 'green';
  size?: 'flat' | 'circle';
  wide?: boolean;
  options: any;
  active: number;
  title?: string;
  onChangeActive?: (id: number) => void;
}

export const StepsProgress: VFC<StepsProgressProps> = ({
  className,
  options,
  color = 'red',
  size = 'flat',
  active,
  title,
  wide = false,
  onChangeActive,
}) => {
  return (
    <div className={clsx(s.stepsProgress, className)}>
      {size === 'flat' && (
        <ul className={s.flatSteps}>
          {new Array(options).fill('').map((_, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={index}>
              <Button
                color="back-none"
                size="icon"
                onClick={() => onChangeActive(index + 1)}
                className={clsx(s.item, s[color], s[size], { [s.active]: index + 1 === active })}
              />
            </li>
          ))}
        </ul>
      )}

      {size === 'circle' && (
        <>
          {title && (
            <Typography color="gray" size="f12" uppercase className={s.title}>
              {title}
            </Typography>
          )}
          <ul className={clsx(s.circleSteps, { [s.wide]: wide })}>
            {options.map(({ value, text }) => (
              <li
                key={value}
                className={clsx(s.item, s[size], { [s.active]: value === active, [s.activeLine]: value < active, [s.wide]: wide })}
              >
                {value !== options.length ? (
                  <img src={value > active ? checkGray : checkGreen} alt="check" />
                ) : (
                  <img src={checkRed} alt="finish" />
                )}
                {/* {value !== active && ( */}
                <Typography color="gray" size="f12" uppercase className={s.itemText}>
                  {text}
                </Typography>
                {/* )} */}
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};
