import { VFC } from 'react';

import cn from 'clsx';

import { Typography } from 'components';
import { powered } from 'assets/img/section';
import s from './styles.module.scss';

export interface PoweredCardProps {
  className?: string;
}

export const PoweredCard: VFC<PoweredCardProps> = ({ className }) => {
  return (
    <a
      href="https://rocknblock.io/"
      target="_blank"
      rel="noreferrer"
      className={cn(s.poweredCard, className)}
    >
      <Typography type="span" color="gray" uppercase className={s.title}>
        Powered by
      </Typography>
      <img src={powered} alt="powered-by" />
    </a>
  );
};
