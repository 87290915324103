import { FC, ChangeEvent, useCallback, useMemo, FocusEvent } from 'react';

import BigNumber from 'bignumber.js/bignumber';
import clsx from 'clsx';

import { getRegexp } from 'utils';
// import { useWindowState } from 'hooks';
import s from './styles.module.scss';

export interface IInput {
  type?: 'text' | 'password' | 'email' | 'number' | 'date' | 'datetime-local';
  id: string;
  isNumber?: boolean;
  integer?: boolean;
  readonly?: boolean;
  placeholder?: string;
  value?: string | number;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
  min?: number;
  max?: number;
  positiveOnly?: boolean;
  name?: string;
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  field?: any;
  autoComplite?: 'off' | 'on';
  disabled?: boolean;
}

export const Input: FC<IInput> = ({
  isNumber = false,
  integer = false,
  readonly = false,
  disabled = false,
  placeholder = '',
  value,
  id,
  type,
  onChange,
  onBlur,
  min,
  max,
  positiveOnly,
  autoComplite = 'off',
  name,
  className,
  field,
}) => {
  // const { width } = useWindowState();
  const reg = getRegexp(positiveOnly, integer);
  const checkMin = useCallback(
    (comparingValue: string) => {
      if (new BigNumber(min ?? 0).isLessThanOrEqualTo(comparingValue)) {
        return true;
      }

      const arrayedComparingValue = Array.from(String(comparingValue), Number);
      const arrayedMin = Array.from(String(min), Number);

      for (let i = 0; i < arrayedComparingValue.length; i += 1) {
        const comparingItem = new BigNumber(arrayedMin[i]).isLessThanOrEqualTo(new BigNumber(arrayedComparingValue[i]));
        const itemsIsNumbers = Number.isNaN(arrayedMin[i]) && Number.isNaN(arrayedComparingValue[i]);

        const itemsIsExists = arrayedComparingValue[i] !== undefined && arrayedMin[i] === undefined;

        if (!(comparingItem || itemsIsNumbers || itemsIsExists)) {
          return false;
        }
      }
      return true;
    },
    [min],
  );

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const inputValue = event.target.value;
      if (onChange) {
        if (inputValue === '') {
          onChange(event);
        }
        if (
          (Number.isNaN(+inputValue) && !reg.test(inputValue)) ||
          (positiveOnly && inputValue === '-') ||
          (integer && !reg.test(inputValue))
        ) {
          return;
        }

        const valueLessThen = new BigNumber(inputValue).isLessThan(new BigNumber(max));
        const valueEqualTo = new BigNumber(inputValue).isEqualTo(new BigNumber(max));
        if ((max || max === 0) && min) {
          if (checkMin(inputValue) && (valueLessThen || valueEqualTo)) {
            onChange(event);
          }
        } else if (max || max === 0) {
          if (valueLessThen || valueEqualTo) {
            onChange(event);
          }
        } else if (min) {
          if (checkMin(inputValue)) {
            onChange(event);
          }
        } else onChange(event);
      }
    },
    [checkMin, integer, max, min, onChange, positiveOnly, reg],
  );

  const handleInputChange = useMemo(() => {
    return isNumber ? handleChange : onChange;
  }, [isNumber, handleChange, onChange]);

  const handleOpenPicker = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      const isChromium = window.navigator.userAgent.indexOf('Chrome') > 0;
      if ((type === 'date' || type === 'datetime-local') && isChromium) {
        // @ts-ignore
        event.target.showPicker();
      }
    },
    [type],
  );

  return (
    <input
      id={id}
      name={name}
      autoComplete={autoComplite}
      className={clsx(s.input, className)}
      type={type}
      readOnly={readonly}
      placeholder={placeholder}
      value={value}
      onChange={handleInputChange}
      onBlur={onBlur}
      onFocus={handleOpenPicker}
      disabled={disabled}
      {...field}
    />
  );
};
