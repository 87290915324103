import { Button, InputWrapper, Typography } from 'components';
import { Field, FieldProps, Form, Formik } from 'formik';
import { useShallowSelector } from 'hooks';
import { useCallback, useMemo, useRef, VFC } from 'react';
import { useDispatch } from 'react-redux';
import { resetPassword } from 'store/user/actions';
import { AccountProps, INotifyModalProps, Modals, RequestStatus } from 'types';
import uiSelector from 'store/ui/selectors';
import actionTypes from 'store/user/actionTypes';
import { resetFormConfig, resetInitialState, resetValidationSchema } from './ResetPassword.helper';
import s from '../styles.module.scss';

interface IResetPasswordProps extends INotifyModalProps {
  onReturnModal: (type: Modals) => void;
}

export const ResetPassword: VFC<IResetPasswordProps> = ({ data, onReturnModal }) => {
  const formikRef = useRef(null);

  const { [actionTypes.RESET_PASSWORD]: resetPasswordRequestStatus } = useShallowSelector(
    uiSelector.getUI,
  );
  const dispatch = useDispatch();

  const isResetPasswordLoading = useMemo(
    () => resetPasswordRequestStatus === RequestStatus.REQUEST,
    [resetPasswordRequestStatus],
  );

  const handleSubmit = useCallback(
    (values: Partial<AccountProps>) => {
      const { email } = values;
      dispatch(resetPassword({ email }));
    },
    [dispatch],
  );

  return (
    <div className={s.resetPassword}>
      <Typography type="h3" color="white">
        {data?.title}
      </Typography>
      <Formik
        innerRef={formikRef}
        enableReinitialize
        initialValues={resetInitialState}
        validationSchema={resetValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, values, handleChange, handleBlur, handleSubmit, isValid, dirty }) => (
          <Form translate={undefined}>
            {resetFormConfig.map(({ id, name, renderProps }) => (
              <Field id={id} name={name} key={id}>
                {({ form: { isSubmitting } }: FieldProps) => (
                  <InputWrapper
                    id={id}
                    type={renderProps.type}
                    title={renderProps.label}
                    value={values[name]}
                    placeholder={renderProps.placeholder}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={isSubmitting}
                    error={!!errors[name] && touched[name] ? (errors[name] as string) : undefined}
                    className={s.resetPasswordInput}
                    autoComplite="off"
                  />
                )}
              </Field>
            ))}
            <Button
              color="outline-green"
              onClick={handleSubmit}
              disabled={!isValid || !dirty}
              className={s.resetPasswordBtn}
              isLoading={isResetPasswordLoading}
            >
              Dissmiss
            </Button>
            <Button color="back-none" size="icon" onClick={() => onReturnModal(Modals.SignIn)}>
              <Typography color="red" border>
                Return to sign in
              </Typography>
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};
