/* eslint-disable jsx-a11y/anchor-is-valid */
import { VFC } from 'react';
import cn from 'clsx';
import { Button, StatusField, Typography, CardBox } from 'components';
import { arrowRight } from 'assets/img/section';
import { ContentCardProps } from 'types/card';
import { dateFormatter } from 'utils';
import { networkIcons } from 'config/constants';
import { ChainsEnum } from 'types';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import s from './styles.module.scss';

export const ContentCard: VFC<ContentCardProps> = ({ className, data }) => {
  return (
    <Link to={`/ido/project/${data.id}`}>
      <CardBox className={cn(s.card, className)}>
        <div className={s.top}>
          <div className={s.topName}>
            <img src={data.logo} alt="logo" />
            <a data-tip data-for="project-name">
              <Typography color="white">{data.projectName}</Typography>
            </a>
            <ReactTooltip id="project-name" place="bottom" type="light" effect="solid" className={s.tooltipText}>
              <Typography type="span">{data.projectName}</Typography>
            </ReactTooltip>
          </div>
          <StatusField stage={data.stage} />
        </div>

        <div className={s.body}>
          <div className={s.bodyItem}>
            <Typography color="gray">Raise network:</Typography>
            <Typography color="white">
              <img src={networkIcons[ChainsEnum[data.networkName]]} alt="icon" />
              {data.networkName}
            </Typography>
          </div>
          <div className={s.bodyItem}>
            <Typography color="gray">Soft Cap:</Typography>
            <Typography color="white">{data.softCap}</Typography>
          </div>
          <div className={s.bodyItem}>
            <Typography color="gray">Target raise:</Typography>
            <Typography color="white">{data.targetRace}</Typography>
          </div>
          {data.fundingToken && (
            <div className={s.bodyItem}>
              <Typography color="gray">Funding ends in:</Typography>
              <Typography color="white">{dateFormatter(data.fundingEndTime)}</Typography>
            </div>
          )}
        </div>
        <div className={s.bottom}>
          <Button icon={arrowRight} color="back-none" className={s.viewBtn}>
            View project
          </Button>
        </div>
      </CardBox>
    </Link>
  );
};
