import { configureStore } from '@reduxjs/toolkit';
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';

import userActionTypes from './user/actionTypes';
import projectActionTypes from './projects/actionTypes';
import balancesActionTypes from './balances/actionTypes';
import reducer from './rootReducer';
import rootSaga from './rootSaga';

const sagaMiddleware = createSagaMiddleware();

const userPersistConfig = {
  key: 'user',
  storage,
  whitelist: ['provider', 'key', 'id', 'network', 'isAdmin'],
};

const reducers = {
  ...reducer,
  user: persistReducer(userPersistConfig, reducer.user),
};

const store = configureStore({
  reducer: reducers,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  middleware: (getDefaultMiddleware: any) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
          userActionTypes.UPDATE_USER_INFO,
          userActionTypes.SIGN_IN,
          userActionTypes.SIGN_UP,
          projectActionTypes.CREATE_PROJECT,
          projectActionTypes.SIGNING_PROJECT,
          projectActionTypes.CHANGE_PROJECT_STATUS,
          projectActionTypes.GET_RPOJECT_STATUS,
          projectActionTypes.STAKE,
          projectActionTypes.GET_CURRENT_PROJECT,
          balancesActionTypes.GET_BALANCES_INFO,
          balancesActionTypes.GET_STAKED_SO_FAR,
          balancesActionTypes.GET_TOTORO_BALANCE,
        ],
      },
    }).concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);
const persistor = persistStore(store);

export default { store, persistor };
