import { Modal } from 'components/Modal';
import { useCallback, VFC } from 'react';

import { Modals, ModalsInitialState, State } from 'types/store';
import modalsSelector from 'store/modals/selectors';
import { useDispatch } from 'react-redux';
import { setActiveModal } from 'store/modals/reducer';
import { useShallowSelector } from 'hooks';
import { modalData } from './NotificationModal.helper';
import { ConnectWalletModal, SignInModal } from './index';

import s from './styles.module.scss';
import { LoginAndAuth } from './LoginAndAuth';
import { ResetPassword } from './ResetPassword';

export interface NotificationModalProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onConnectWallet: (provider: any, newChain: any) => void;
  initConnectToRequiredChain: () => void;
  className?: string;
}

export const NotificationModal: VFC<NotificationModalProps> = ({ onConnectWallet, initConnectToRequiredChain }) => {
  const dispatch = useDispatch();
  const { modalState } = useShallowSelector<State, ModalsInitialState>(modalsSelector.getModals);
  const data = modalData[modalState.activeModal];

  const closeModal = useCallback(() => {
    dispatch(
      setActiveModal({
        activeModal: Modals.init,
        txHash: '',
        open: false,
      }),
    );
  }, [dispatch]);

  const openModal = useCallback(
    (type: Modals) => {
      dispatch(
        setActiveModal({
          activeModal: type,
          txHash: '',
          open: true,
        }),
      );
    },
    [dispatch],
  );

  return (
    <>
      {modalState.activeModal === Modals.ConnectWallet && (
        <Modal open={modalState.open} onClose={closeModal} className={s.root}>
          <ConnectWalletModal
            data={data}
            closeModal={closeModal}
            onConnectWallet={onConnectWallet}
            initConnectToRequiredChain={initConnectToRequiredChain}
          />
        </Modal>
      )}
      {modalState.activeModal === Modals.SignIn && (
        <Modal open={modalState.open} onClose={closeModal} className={s.root}>
          <SignInModal data={data} closeModal={closeModal} onOpenResetModal={openModal} />
        </Modal>
      )}
      {modalState.activeModal === Modals.LoginAndAuth && (
        <Modal open={modalState.open} onClose={() => {}} className={s.root} closable={false} closeIcon={false}>
          <LoginAndAuth data={data} closeModal={closeModal} />
        </Modal>
      )}
      {modalState.activeModal === Modals.ResetPassword && (
        <Modal size="fc" open={modalState.open} onClose={closeModal} className={s.root}>
          <ResetPassword data={data} closeModal={closeModal} onReturnModal={openModal} />
        </Modal>
      )}
    </>
  );
};
