import { VFC } from 'react';

import clsx from 'clsx';

import { SkeletonLoader } from 'components';
import s from './styles.module.scss';

export interface SkeletonPoolFormProps {
  className?: string;
}

export const SkeletonPoolForm: VFC<SkeletonPoolFormProps> = ({ className }) => {
  const WIDTH_ROW_TITLE = '100px';
  const WIDTH_ROW_TEXT = '300px';

  return (
    <div className={clsx(s.card, className)}>
      <div className={s.container}>
        <div className={s.row}>
          <SkeletonLoader width={WIDTH_ROW_TITLE} />
          <SkeletonLoader width={WIDTH_ROW_TEXT} />
        </div>
        <div className={s.row}>
          <SkeletonLoader width={WIDTH_ROW_TITLE} />
          <SkeletonLoader width={WIDTH_ROW_TEXT} />
        </div>
        <div className={s.row}>
          <SkeletonLoader width={WIDTH_ROW_TITLE} />
          <SkeletonLoader width={WIDTH_ROW_TEXT} />
        </div>
        <div className={s.row}>
          <SkeletonLoader width={WIDTH_ROW_TITLE} />
          <SkeletonLoader width={WIDTH_ROW_TEXT} />
        </div>
      </div>
    </div>
  );
};
