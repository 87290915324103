import { useEffect } from 'react';
import { State, UserState } from 'types';
import userSelector from 'store/user/selectors';
import { useNavigate } from 'react-router-dom';
import useShallowSelector from './useShallowSelector';

export const useCheckNotAllowRoute = (pathname: string) => {
  const { isAdmin, key } = useShallowSelector<State, UserState>(userSelector.getUser);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      (pathname === '/admin' && !isAdmin) ||
      (pathname === '/project-create' && key.length === 0) ||
      (pathname === '/project-create' && isAdmin)
    ) {
      navigate('/');
    }
  }, [isAdmin, key.length, navigate, pathname]);
};
