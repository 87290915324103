import { put, takeLatest, call } from 'redux-saga/effects';
import apiActions from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import { toast } from 'react-toastify';
import { notifyText } from 'config/constants';
import { deletePost } from '../actions';
import actionTypes from '../actionTypes';
import { getPostsSaga } from './getPostsSaga';

export function* deletePostSaga({ type, payload: { id } }: ReturnType<typeof deletePost>) {
  yield put(apiActions.request(type));
  try {
    yield call(baseApi.deletePost, id);

    toast.success(notifyText.deletePost.success);
    yield call(getPostsSaga, {
      type: actionTypes.GET_POSTS,
      payload: undefined,
    });

    yield put(apiActions.success(type));
  } catch (err) {
    yield put(apiActions.error(type, err));
    toast.success(notifyText.deletePost.error);
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.DELETE_POST, deletePostSaga);
}
