import { FC, Suspense } from 'react';

import { Layout, RouteManager as Router } from 'containers';
import { ToastContainer } from 'react-toastify';

import { WalletConnectContext } from 'services/walletConnect';

import 'react-toastify/dist/ReactToastify.css';
import { Spinner } from 'components';

const App: FC = () => (
  <Suspense fallback={<Spinner size="lg" />}>
    <WalletConnectContext>
      <ToastContainer
        autoClose={4000}
        hideProgressBar
        position="bottom-right"
        closeButton
      />
      <Layout>
        <Router />
      </Layout>
    </WalletConnectContext>
  </Suspense>
);
export default App;
