const GET_ALL_PROJECTS = 'GET_ALL_PROJECTS' as const;
const GET_USER_PROJECTS = 'GET_USER_PROJECTS' as const;
const GET_TOTAL_FILTER_PROJECTS = 'GET_TOTAL_FILTER_PROJECTS' as const;
const GET_PROJECT_INFO = 'GET_PROJECT_INFO' as const;

const GET_CURRENT_PROJECT = 'GET_CURRENT_PROJECT' as const;
const CREATE_PROJECT = 'CREATE_PROJECT' as const;
const SIGNING_PROJECT = 'SIGNING_PROJECT' as const;
const CHANGE_PROJECT_STATUS = 'CHANGE_PROJECT_STATUS' as const;
const GET_RPOJECT_STATUS = 'GET_RPOJECT_STATUS' as const;

const STAKE = 'STAKE' as const;
const FUND = 'FUND' as const;
const WITHDRAW = 'WITHDRAW' as const;
const CLAIM_REWARD = 'CLAIM_REWARD' as const;
const WITHDRAW_OWNER = 'WITHDRAW_OWNER' as const;

export default {
  GET_ALL_PROJECTS,
  GET_USER_PROJECTS,
  GET_TOTAL_FILTER_PROJECTS,
  GET_PROJECT_INFO,

  GET_CURRENT_PROJECT,
  CREATE_PROJECT,
  SIGNING_PROJECT,
  CHANGE_PROJECT_STATUS,
  GET_RPOJECT_STATUS,

  STAKE,
  FUND,
  WITHDRAW,
  CLAIM_REWARD,
  WITHDRAW_OWNER,
};
