import { useCallback, useMemo, useState, VFC } from 'react';

import clsx from 'clsx';

import { logo } from 'assets/img/section';
import { Button, Modal, StepsProgress, Typography } from 'components';
import { disclaimerSteps } from './Disclaimer.helper';
import s from './styles.module.scss';

export interface DisclaimerProps {
  className?: string;
  isOpen: boolean;
  onClose: () => void;
}

export const Disclaimer: VFC<DisclaimerProps> = ({ className, isOpen, onClose }) => {
  const [currentStep, setCurrentStep] = useState(1);

  const currentData = useMemo(() => {
    return disclaimerSteps[currentStep];
  }, [currentStep]);

  const handleChangeStep = useCallback(
    (type: 'next' | 'prev') => {
      if (type === 'next' && currentStep !== 4) {
        setCurrentStep(currentStep + 1);
      } else if (type === 'prev') {
        setCurrentStep(currentStep - 1);
      } else if (currentStep === 4) {
        onClose();
        const cookiesId = Math.floor(Math.random() * 100);
        const cookiesExpirationTime = 86400;
        document.cookie = `TOTOSESSION=${cookiesId}; max-age=${cookiesExpirationTime}`;
      }
    },
    [currentStep, onClose],
  );

  return (
    <Modal
      open={isOpen}
      onClose={() => {}}
      closeIcon={false}
      closable={false}
      className={clsx(s.disclaimer, className)}
    >
      <div className={s.container}>
        <img src={logo} alt="logo" />
        <div className={s.steps}>
          <div className={s.content}>
            <Typography type="h3">{currentData.title}</Typography>
            <Typography type="span" size="f12" uppercase>
              {!!currentData.subtitle.length && currentData.subtitle}
            </Typography>

            <Typography color="gray" size="f16" className={s.text}>
              {currentData.content}
            </Typography>
          </div>
          <div className={s.scrollInfo}>
            <Typography color="red" size="f16" border>
              Scroll for more
            </Typography>
          </div>

          <div className={s.bottom}>
            <StepsProgress options={4} onChangeActive={(id) => setCurrentStep(id)} active={currentStep} />
            <div className={s.btns}>
              {currentStep > 1 && (
                <Button color="outline" onClick={() => handleChangeStep('prev')}>
                  Back
                </Button>
              )}

              {currentStep < 4 ? (
                <Button color="outline" onClick={() => handleChangeStep('next')}>
                  Next
                </Button>
              ) : (
                <Button color="outline" onClick={() => handleChangeStep('next')}>
                  Finish
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
