import { CSSProperties, FC, PropsWithChildren, RefObject, SyntheticEvent } from 'react';
import { Link } from 'react-router-dom';

import cn from 'clsx';

import { Spinner } from 'components';
import s from './styles.module.scss';

export interface ButtonProps {
  color?: 'default' | 'filled' | 'outline' | 'outline-blue' | 'outline-green' | 'disabled' | 'back-none' | 'gray';
  size?: 'ssm' | 'sm' | 'md' | 'lg' | 'slg' | 'icon';
  className?: string;
  onClick?: (event: never) => void;
  type?: 'button' | 'submit';
  disabled?: boolean;
  isLoading?: boolean;
  onMouseLeave?: (event: never) => void;
  onMouseOver?: (event: SyntheticEvent) => void;
  style?: CSSProperties;
  href?: string;
  icon?: string;
  rowReverse?: boolean;
  btnRef?: RefObject<HTMLButtonElement>;
}

export const Button: FC<PropsWithChildren<ButtonProps>> = ({
  color = 'default',
  size = 'md',
  onClick = () => {},
  className,
  type = 'button',
  children,
  disabled,
  isLoading = false,
  href,
  icon,
  rowReverse = false,
  btnRef,
  onMouseLeave,
  onMouseOver = () => {},
}) => {
  if (href) {
    return (
      <Link
        to={href}
        className={cn(className, s.button, s[color], s[size], {
          [s.disabled]: disabled || color === 'disabled',
        })}
      >
        {children}
        {icon && <img src={icon} alt="icon" />}
      </Link>
    );
  }
  return (
    <button
      ref={btnRef}
      type={type === 'submit' ? 'submit' : 'button'}
      className={cn(s.button, s[color], s[size], className, {
        [s.reverse]: rowReverse,
        [s.disabled]: disabled || isLoading || color === 'disabled',
      })}
      onClick={onClick}
      disabled={disabled || isLoading}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseOver}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {children}
          {icon && <img src={icon} alt="icon" />}
        </>
      )}
    </button>
  );
};
