import { VFC } from 'react';
import Upload from 'rc-upload';
import { UploadProps } from 'rc-upload/lib';
import { plusCircle, uploadAvatar } from 'assets/img/section';
import { Typography } from 'components/Typography';
import { Button } from 'components/Button';
import clsx from 'clsx';
import s from './styles.module.scss';

export interface IUploadProps extends UploadProps {
  title?: string;
  subtitle?: string;
  className?: string;
  img?: string;
  error?: string;
  accept?: string;
  formSize?: 'lg' | 'circle';
  onDelete?: () => void;
}

export const Uploader: VFC<IUploadProps> = (props) => {
  const {
    title,
    subtitle,
    className,
    img,
    error,
    onDelete,
    accept = '.jpg, .png, .jpeg',
    size,
    formSize = 'lg',
    ...other
  } = props;
  return (
    <div className={s.uploaderWrapper}>
      <Upload
        accept={accept}
        prefixCls={clsx(s.uploader, s[formSize], { [s.error]: !!error && formSize !== 'circle' }, className)}
        {...other}
      >
        {img ? (
          <div className={clsx(s.uploaderLoaded, s[formSize])}>
            <img src={img} className={clsx(s.uploaderLoadedPreview, s[formSize])} alt="icon" />

            {formSize === 'circle' && <div className={clsx(s.uploaderTitle, s[formSize])}>{title}</div>}
            {formSize === 'lg' && (
              <Button color="filled" onClick={onDelete} className={s.uploaderLoadedBtn}>
                Delete
              </Button>
            )}
          </div>
        ) : (
          <>
            <img
              className={clsx(s.uploaderImg, s[formSize], {
                [s.error]: !!error && formSize === 'circle',
              })}
              src={formSize === 'lg' ? plusCircle : uploadAvatar}
              alt="icon"
            />
            {title && <div className={clsx(s.uploaderTitle, s[formSize])}>{title}</div>}
            {subtitle && (
              <Typography color="gray" className={s.uploaderSubtitle}>
                {subtitle}
              </Typography>
            )}
            {error && <div className={s.uploaderError}>{error}</div>}
          </>
        )}
        {error && <div className={s.uploaderError}>{error}</div>}
      </Upload>
    </div>
  );
};
