import { call, put, select, takeLatest } from 'redux-saga/effects';
import apiActions from 'store/api/actions';
import userSelector from 'store/user/selectors';
import { contractsConfig, ContractsNames } from 'config';
import { getTokenAmount } from 'utils';
import { toast } from 'react-toastify';
import { notifyText } from 'config/constants';
import { getBalanceInfoSaga } from 'store/balances/sagas/getBalanceInfoSaga';
import { getStakedSoFarSaga } from 'store/balances/sagas/getStakedSoFarSaga';
import { getTotoroBalanceSaga } from 'store/balances/sagas/getTotoroBalanceSaga';
import { approveSaga } from '../../user/sagas/approveSaga';
import actionTypes from '../actionTypes';
import userActionTypes from '../../user/actionTypes';
import balanceActionTypes from '../../balances/actionTypes';
import { stake } from '../actions';

export function* stakeSaga({ type, payload: { web3Provider, pid, activeTab, amount } }: ReturnType<typeof stake>) {
  yield put(apiActions.request(type));
  const myAddress = yield select(userSelector.getProp('address'));
  const network = yield select(userSelector.getProp('network'));
  const chainType = yield select(userSelector.getProp('chainType'));
  const activePidValue = pid * 3 + activeTab;

  const { abi: totopadAbi, address: stakingAddress } = contractsConfig.contracts[ContractsNames.totopad][chainType];
  const { address: totoroAddress, abi: totoroAbi } = contractsConfig.contracts[ContractsNames.totoro][chainType];

  try {
    const totopadContract = yield new web3Provider.eth.Contract(totopadAbi, stakingAddress[network]);
    const totoroContract = yield new web3Provider.eth.Contract(totoroAbi, totoroAddress[network]);

    const decimals = yield call(totoroContract.methods.decimals().call);
    const amountWithDecimals = getTokenAmount(amount, decimals);

    if (myAddress) {
      yield call(approveSaga, {
        type: userActionTypes.APPROVE,
        payload: {
          web3Provider,
          spender: totoroAddress[network],
          tokenAddress: stakingAddress[network],
          amount,
          decimals,
        },
      });
      yield call(totopadContract.methods.pledge(activePidValue, amountWithDecimals).send, {
        from: myAddress,
        to: stakingAddress[network],
      });

      // updating balances -----
      yield call(getBalanceInfoSaga, {
        type: balanceActionTypes.GET_BALANCES_INFO,
        payload: {
          web3Provider,
          activeLimit: activeTab,
        },
      });
      yield call(getStakedSoFarSaga, {
        type: balanceActionTypes.GET_STAKED_SO_FAR,
        payload: {
          web3Provider,
          pid,
        },
      });
      yield call(getTotoroBalanceSaga, {
        type: balanceActionTypes.GET_TOTORO_BALANCE,
        payload: {
          web3Provider,
        },
      });
    }

    toast.success(notifyText.stake.success);
    yield put(apiActions.success(type));
  } catch (err) {
    console.log(err);
    toast.error(notifyText.stake.error);
    yield put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.STAKE, stakeSaga);
}
