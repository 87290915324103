import { VFC } from 'react';
import { Typography } from 'components/Typography';
import clsx from 'clsx';
import { Button } from 'components/Button';
import { OptionProps } from 'types';
import s from './styles.module.scss';

export interface SwitcherProps {
  className?: string;
  option: OptionProps[];
  active: OptionProps;
  onChangeActive: (option: OptionProps) => void;
}

export const Switcher: VFC<SwitcherProps> = ({ className, option, active, onChangeActive }) => {
  return (
    <ul className={clsx(s.switcher, className)}>
      {option.map(({ value, text }) => (
        <li key={value}>
          <Button color="back-none" size="icon" onClick={() => onChangeActive({ value, text })}>
            <Typography size="f16" className={clsx(s.item, { [s.active]: value === active.value })}>
              {text}
            </Typography>
          </Button>
        </li>
      ))}
    </ul>
  );
};
