import { useCallback, useMemo, VFC } from 'react';
import { Select, Typography } from 'components';
import cn from 'clsx';
import { IFiltersTypes, IFilterItemProps } from 'pages/FiltersPage/FiltersPage.type';
import s from './styles.module.scss';
import { raisFilterItems } from './Filters.helper';

export interface FiltersProps {
  className?: string;
  activeCategory: IFilterItemProps;
  onFiltersChange: (filteItem: IFilterItemProps) => void;
}

export const Filters: VFC<FiltersProps> = ({ className, activeCategory, onFiltersChange }) => {
  const filterTitle = (
    <Typography size="f12" color="gray" uppercase>
      Raise Network:
    </Typography>
  );

  const handleChangeCategory = useCallback(
    ({ value, text }) => {
      onFiltersChange({ value: +value, text: text as IFiltersTypes });
    },
    [onFiltersChange],
  );

  const getCurrentCategoryName = useMemo(() => {
    return raisFilterItems.find(({ value }) => value === activeCategory.value);
  }, [activeCategory.value]);

  return (
    <div className={cn(s.filters, className)}>
      <Select
        title={filterTitle}
        color="gray"
        size="mini"
        arrow
        options={raisFilterItems}
        defaultOption={getCurrentCategoryName}
        onSelect={handleChangeCategory}
      />
    </div>
  );
};
