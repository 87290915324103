import { AccountProps, IInputFormikProps } from 'types';
import * as Yup from 'yup';

export const signInInitialvalues: Partial<AccountProps> = {
  email: '',
  password: '',
};

export const signInValidationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email format').min(5).required('Incorrect notation'),
  password: Yup.string().required('Incorrect notation'),
  // password: Yup.string().min(8, 'Should be 8 chars minimum.').required('Incorrect notation'),
});

export const signInFormConfig: IInputFormikProps[] = [
  {
    id: 'email',
    name: 'email',
    renderProps: {
      label: 'Email address',
      name: 'email',
      type: 'text',
      placeholder: 'Enter e-mail',
    },
  },
  {
    id: 'password',
    name: 'password',
    renderProps: {
      label: 'Password',
      name: 'password',
      type: 'password',
      placeholder: 'Password',
    },
  },
];
