const GET_TOKEN_BALANCE = 'GET_TOKEN_BALANCE' as const;
const UPDATE_USER_INFO = 'UPDATE_USER_INFO' as const;
const GET_USER_INFO = 'GET_USER_INFO' as const;
const RESET_PASSWORD = 'RESET_PASSWORD' as const;
const LOGIN = 'LOGIN' as const;
const SIGN_IN = 'SIGN_IN' as const;
const SIGN_UP = 'SIGN_UP' as const;
const APPROVE = 'APPROVE' as const;
const CREATE_POST = 'CREATE_POST' as const;
const GET_POSTS = 'GET_POSTS' as const;
const DELETE_POST = 'DELETE_POST' as const;

export default {
  GET_TOKEN_BALANCE,
  UPDATE_USER_INFO,
  LOGIN,
  SIGN_IN,
  SIGN_UP,
  RESET_PASSWORD,
  GET_USER_INFO,
  APPROVE,
  CREATE_POST,
  GET_POSTS,
  DELETE_POST,
};
