import { call, put, select, takeLatest } from 'redux-saga/effects';
import apiActions from 'store/api/actions';
import userSelector from 'store/user/selectors';
import { contractsConfig, ContractsNames } from 'config';
import { getTokenAmountDisplay } from 'utils';
import { updateBalancesState } from 'store/balances/reducer';
import actionTypes from '../actionTypes';
import { getTotoroBalance } from '../actions';

export function* getTotoroBalanceSaga({
  type,
  payload: { web3Provider },
}: ReturnType<typeof getTotoroBalance>) {
  yield put(apiActions.request(type));

  const myAddress = yield select(userSelector.getProp('address'));
  const network = yield select(userSelector.getProp('network'));
  const chainType = yield select(userSelector.getProp('chainType'));

  const { abi: tokenAbi, address: tokenAddress } =
    contractsConfig.contracts[ContractsNames.totoro][chainType];

  try {
    const tokenContract = yield new web3Provider.eth.Contract(tokenAbi, tokenAddress[network]);

    if (myAddress) {
      const balance = yield call(tokenContract.methods.balanceOf(myAddress).call);
      const decimals = yield call(tokenContract.methods.decimals().call);

      yield put(
        updateBalancesState({
          token: getTokenAmountDisplay(balance, decimals),
        }),
      );
    }
    yield put(apiActions.success(type));
  } catch (err) {
    console.log(err);
    yield put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_TOTORO_BALANCE, getTotoroBalanceSaga);
}
