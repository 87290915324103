import { fork } from 'redux-saga/effects';
import userSaga from 'store/user/sagas';
import projectsSaga from 'store/projects/sagas';
import balancesSaga from 'store/balances/sagas';
import postsSaga from 'store/posts/sagas';
/* PLOP_INJECT_IMPORT_SAGA */

export default function* rootSaga() {
  yield fork(userSaga);
  yield fork(projectsSaga);
  yield fork(balancesSaga);
  yield fork(postsSaga);
  /* PLOP_INJECT_FORK_SAGA */
}
