export const routes = {
  root: '/',
  title: 'Home',
  // stakingFunding: {
  //   root: '/staking-funding',
  //   title: 'Staking / Funding',
  // },
  // distributing: {
  //   root: '/distributing',
  //   title: 'Distributing',
  //   ...controlsParams,
  // },
  // await: {
  //   root: '/await',
  //   title: 'Await',
  //   ...controlsParams,
  // },
  // complited: {
  //   root: '/complited',
  //   title: 'Complited',
  //   ...controlsParams,
  // },
  announcements: {
    root: '/announcements',
    title: 'Announcements',
  },
  projects: {
    create: {
      root: '/project-create',
      title: 'Create Project',
    },
    all: {
      root: '/ido/:stage/*',
      title: 'All Projects',
      getUrl: (stage: string) => `/ido/${stage}`,
    },
    current: {
      root: '/ido/project/:id/*',
      title: 'Project',
    },
  },
  account: {
    details: {
      root: '/account/details',
      title: 'My Details',
    },
    projects: {
      root: '/account/projects',
      title: 'My Projects',
    },
  },
  signup: {
    root: '/signup',
    title: 'Create account',
  },
  admin: {
    root: '/admin',
    title: 'Admin page',
  },
};
