import { routes } from 'appConstants/routes';

export const getRouteTitle = (pathname: string): string => {
  let title;
  switch (pathname) {
    case '/signup':
      title = routes.signup.title;
      break;
    case '/announcements':
      title = routes.announcements.title;
      break;
    case '/account/details':
      title = routes.account.details.title;
      break;
    case '/account/projects':
      title = routes.account.projects.title;
      break;

    default:
      title = routes.projects.current.title;
  }
  return title;
};
