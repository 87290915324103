import { logo } from 'assets/img/section';
import { HeaderControls } from 'containers/Header/HeaderControls';
import { VFC } from 'react';
import { Link } from 'react-router-dom';
import { HeaderNavs } from './HeaderNavs';

import s from './styles.module.scss';

export interface HeaderProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onConnectWallet: (provider: any, newChain: any) => void;
  disconnect: () => void;
}

export const Header: VFC<HeaderProps> = ({ onConnectWallet, disconnect }) => {
  return (
    <header className={s.wrapper}>
      <div className={s.logo}>
        <Link to="/">
          <img src={logo} alt="logo" />
        </Link>
      </div>
      <div className={s.container}>
        <HeaderControls disconnect={disconnect} onConnectWallet={onConnectWallet} />
        <HeaderNavs />
      </div>
    </header>
  );
};
