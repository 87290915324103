import { put, takeLatest, call } from 'redux-saga/effects';
import apiActions from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import { toast } from 'react-toastify';
import { notifyText } from 'config/constants';
import actionTypes from '../actionTypes';
import { updateUserInfo } from '../actions';

export function* updateuserInfoSaga({
  type,
  payload: { data },
}: ReturnType<typeof updateUserInfo>) {
  yield put(apiActions.request(type));
  try {
    const response = yield call(baseApi.updateUserInfo, {
      ...data,
    });

    if (response.data.Error) {
      toast.error(response.data.Error.details);
    } else {
      const updatingKeyName = Object.keys(data)[0];
      toast.success(notifyText.updateInfo.success(updatingKeyName));
    }

    yield put(apiActions.success(type));
  } catch (err) {
    console.log(err);
    yield put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.UPDATE_USER_INFO, updateuserInfoSaga);
}
