import { FC } from 'react';

import clsx from 'clsx';
import Dialog, { DialogProps } from 'rc-dialog';
import { close } from 'assets/img/section';
import 'styles/components/modals.scss';
import s from './styles.module.scss';

export interface ModalProps extends DialogProps {
  size?: 'sm' | 'md' | 'lg' | 'fc';
  open: boolean;
  onClose: () => void;
  className?: string;
  wrapperClassName?: string;
}

export const Modal: FC<ModalProps> = ({
  open = false,
  className,
  wrapperClassName,
  children,
  onClose,
  size = 'md',
  closable = true,
  ...props
}) => {
  const CloseIcon = <img src={close} alt="close" className={s.close} />;
  return (
    <Dialog
      visible={open}
      onClose={onClose}
      closable={closable}
      closeIcon={CloseIcon}
      destroyOnClose
      zIndex={1000}
      maskClosable
      className={clsx(s.modalContainer, s[size], className)}
      wrapClassName={clsx(s.modalWrapper, wrapperClassName)}
      {...props}
    >
      {children}
    </Dialog>
  );
};
