import { VFC } from 'react';

import cn from 'clsx';

import { Typography } from 'components/Typography';
import s from './styles.module.scss';

export interface IStageFieldProps {
  stage: 'staking' | 'funding' | 'distributing' | 'completed';
  className?: string;
}

export const StatusField: VFC<IStageFieldProps> = ({ stage = 'staking', className }) => {
  const color =
    stage === 'staking' || stage === 'funding' || stage === 'distributing' ? 'green' : 'gray';
  let text;
  switch (stage) {
    case 'staking':
      text = 'STAKING PHASE';
      break;
    case 'funding':
      text = 'FUNDING PHASE';
      break;
    case 'distributing':
      text = 'DISTRIBUTION PHASE';
      break;
    case 'completed':
      text = 'COMPLETED';
      break;
    default:
      text = 'STAKING PHASE';
  }
  return (
    <div className={cn(s.container, s[stage], className)}>
      <Typography size="f12" color={color}>{text}</Typography>
    </div>
  );
};
