import { useMemo, VFC } from 'react';
import Skeleton, { SkeletonProps } from 'react-loading-skeleton';
import clsx from 'clsx';
import 'react-loading-skeleton/dist/skeleton.css';
import s from './styles.module.scss';

export const HIGHLIGHT_PRIMARY = 'rgba(255, 255, 255, 0.07)';
export const BASE_PRIMARY = 'rgb(61, 62, 62)';

export interface ISkeletonProps extends SkeletonProps {
  className?: string;
  widthMax?: boolean;
  colorScheme?: string;
}

export const SkeletonLoader: VFC<ISkeletonProps> = ({
  circle,
  width,
  height = '22px',
  borderRadius,
  style,
  colorScheme = 'primary',
  widthMax = false,
  className,
}) => {
  const hilightColor = useMemo(() => {
    if (colorScheme === 'primary') {
      return HIGHLIGHT_PRIMARY;
    }
    return HIGHLIGHT_PRIMARY;
  }, [colorScheme]);

  const baseColor = useMemo(() => {
    if (colorScheme === 'primary') {
      return BASE_PRIMARY;
    }
    return BASE_PRIMARY;
  }, [colorScheme]);

  return (
    <Skeleton
      style={style}
      circle={circle}
      width={width}
      height={height}
      borderRadius={borderRadius}
      highlightColor={hilightColor}
      baseColor={baseColor}
      className={clsx(s.skeleton, { [s.skeletonWidthMax]: widthMax }, className)}
    />
  );
};
