/* eslint-disable @typescript-eslint/no-explicit-any */
import { call, put, select, takeLatest } from 'redux-saga/effects';
import apiActions from 'store/api/actions';
import userSelector from 'store/user/selectors';
import balanceSelector from 'store/balances/selectors';
import { contractsConfig, ContractsNames } from 'config';
import { toast } from 'react-toastify';
import { notifyText } from 'config/constants';
import { updateBalancesState } from 'store/balances/reducer';
import actionTypes from '../actionTypes';
import { onWithdrawOwner } from '../actions';

export function* withdrawOwnerSaga({
  type,
  payload: { web3Provider, pid, isSoftCapRecruited },
}: ReturnType<typeof onWithdrawOwner>) {
  yield put(apiActions.request(type));
  const { address, network, chainType } = yield select(userSelector.getUser);
  const { rewardInfo } = yield select(balanceSelector.getBalances);

  const { abi: totopadAbi, address: totopadAddress } = contractsConfig.contracts[ContractsNames.totopad][chainType];

  try {
    const totopadContract = yield new web3Provider.eth.Contract(totopadAbi, totopadAddress[network]);

    if (address) {
      if (isSoftCapRecruited) {
        yield call(totopadContract.methods.claimFundRaising(pid).send, {
          from: address,
          to: totopadAddress[network],
        });
      } else {
        yield call(totopadContract.methods.withdrawReward(pid).send, {
          from: address,
          to: totopadAddress[network],
        });
      }

      yield put(
        updateBalancesState({
          rewardInfo: {
            ...rewardInfo,
            rewardAmount: 0,
          },
        }),
      );

      // yield call(getBalanceInfoSaga, {
      //   type: balanceActionTypes.GET_BALANCES_INFO,
      //   payload: {
      //     web3Provider,
      //     activeLimit: pid,
      //   },
      // });
    }

    toast.success(notifyText.withdraw.success);
    yield put(apiActions.success(type));
  } catch (err) {
    console.log(err);
    toast.error(notifyText.withdraw.error);
    yield put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.WITHDRAW_OWNER, withdrawOwnerSaga);
}
