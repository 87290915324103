import { Button, Typography, Warning } from 'components';
import { useCallback, VFC } from 'react';
import { useNavigate } from 'react-router-dom';
import { INotifyModalProps } from 'types';

import s from '../styles.module.scss';

export const LoginAndAuth: VFC<INotifyModalProps> = ({ data, closeModal }) => {
  const navigate = useNavigate();
  const handleGoHome = useCallback(() => {
    closeModal();
    navigate('/');
  }, [closeModal, navigate]);

  return (
    <div className={s.loginAndAuth}>
      <Typography type="h3" color="white">
        {data?.title}
      </Typography>
      <Typography type="span" color="gray" className={s.loginAndAuthSubtitle}>
        {data?.subtitle}
      </Typography>
      <Warning color="orange-dark" size="fc" text={data?.warningText} className={s.loginAndAuthWarning} />
      <Button color="outline" onClick={handleGoHome} className={s.loginAndAuthBtn}>
        Accept
      </Button>
    </div>
  );
};
