import { IOption } from 'components';
import { Modals } from './store';

export * from './connect';
export * from './store';
export * from './card';

export type TNullable<T> = T | null;
export type TOptionable<T> = T | undefined;

export interface IModalProps {
  className?: string;
  visible: boolean;
  onClose: () => void;
}

export interface INotifyModalProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: { [k: string]: any };
  closeModal: () => void;
}

// eslint-disable-next-line no-shadow
export enum WalletProviders {
  metamask = 'MetaMask',
  walletConnect = 'WalletConnect',
}

export interface IControlsPartProps {
  address: string;
  isAdmin: boolean;
  userKey: string;
  tokenBalance: number;
  network: string;
  currIcon: string;
  isMobile: boolean;
  isNetworkModalVisible: boolean;
  onOpenModal: (type: Modals) => void;
  onDropDownChange: (option: IOption, isMobile?: boolean) => void;
  onChangeNetwork: (type: string) => void;
  handleNetworkOpen: () => void;
  handleNetworkClose: () => void;
}

export interface HeaderControlsProps {
  disconnect: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onConnectWallet: (provider: any, newChain: any) => void;
}

export type IRouteProps = {
  root: string;
  title: string;
};

export type OptionProps = {
  value: number;
  text: string;
};

export interface IInputFormikProps {
  id: string;
  name: string;
  renderProps: {
    label?: string;
    name?: string;
    type?: 'text' | 'password' | 'email' | 'number' | 'date' | 'datetime-local';
    placeholder?: string;
    title?: string;
    subtitle?: string;
    btn?: string;
    withSpacing?: boolean;
    withBorder?: boolean;
    onlyNumbers?: boolean;
    integer?: boolean;
    isTextArea?: boolean;
  };
}

export const ethRegexp = /^0x/;
// export const onlyDigits = /^\d+$/;
export const urlRegexp =
  /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;
