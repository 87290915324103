import { createAction } from '@reduxjs/toolkit';
import {
  CreateProjectReq,
  GetAllProjectsReq,
  GetUserProjectsReq,
  GetCurrentProjectReq,
  SigningProjectReq,
  StakeReq,
  FundReq,
  WithdrawOwnerReq,
  ChangeProjectStatusReq,
  GetpRojectStatusReq,
} from 'types/requests';
import actionTypes from './actionTypes';

export const stake = createAction<StakeReq>(actionTypes.STAKE);
export const fund = createAction<FundReq>(actionTypes.FUND);
export const withdraw = createAction<FundReq>(actionTypes.WITHDRAW);
export const claimReward = createAction<FundReq>(actionTypes.CLAIM_REWARD);
export const onWithdrawOwner = createAction<WithdrawOwnerReq>(actionTypes.WITHDRAW_OWNER);

export const createProject = createAction<CreateProjectReq>(actionTypes.CREATE_PROJECT);
export const signingProject = createAction<SigningProjectReq>(actionTypes.SIGNING_PROJECT);
export const changeProjectStatus = createAction<ChangeProjectStatusReq>(actionTypes.CHANGE_PROJECT_STATUS);
export const getProjectStatus = createAction<GetpRojectStatusReq>(actionTypes.GET_RPOJECT_STATUS);

export const getAllProjects = createAction<GetAllProjectsReq>(actionTypes.GET_ALL_PROJECTS);
export const getCurrentProject = createAction<GetCurrentProjectReq>(actionTypes.GET_CURRENT_PROJECT);
export const getUserProjects = createAction<GetUserProjectsReq>(actionTypes.GET_USER_PROJECTS);
export const getTotalFilterProjects = createAction<GetAllProjectsReq>(actionTypes.GET_TOTAL_FILTER_PROJECTS);
