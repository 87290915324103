import { call, put, takeLatest } from 'redux-saga/effects';
import apiActions from 'store/api/actions';
// import balancesActionTypes from 'store/balances/actionTypes';
import { baseApi } from 'store/api/apiRequestBuilder';
// import { getStakedSoFarSaga } from 'store/balances/sagas/getStakedSoFarSaga';
import { getCurrentProject } from '../actions';
import actionTypes from '../actionTypes';
import { updateProjectsState } from '../reducer';

export function* getCurrentProjectSaga({
  type,
  payload: { id },
}: ReturnType<typeof getCurrentProject>) {
  yield put(apiActions.request(type));

  try {
    const { data } = yield call(baseApi.gerCurrentProject, id);
    yield put(updateProjectsState({ currentProject: data }));

    // yield call(getStakedSoFarSaga, {
    //   type: balancesActionTypes.GET_STAKED_SO_FAR,
    //   payload: {
    //     web3Provider,
    //     pid: data.pid,
    //   },
    // });
    yield put(apiActions.success(type));
  } catch (err) {
    console.log(err);
    yield put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_CURRENT_PROJECT, getCurrentProjectSaga);
}
