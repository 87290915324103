import { routes } from 'appConstants';
import { IOption } from 'components';
import { notifyText } from 'config/constants';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useWalletConnectorContext } from 'services';
import { setActiveModal } from 'store/modals/reducer';
import { updateUserState } from 'store/user/reducer';
import { Modals } from 'types';

export const useHeaderDropdownChange = (onOpenNetworkModal: () => void): ((option: IOption) => void) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { disconnect } = useWalletConnectorContext();

  const handleDropdownChange = useCallback(
    (option: IOption) => {
      const { value } = option;

      if (value === 10) {
        navigate(routes.announcements.root);
      } else if (value === 8) {
        navigate(routes.signup.root);
      } else if (value === 9) {
        dispatch(
          setActiveModal({
            activeModal: Modals.SignIn,
            open: true,
          }),
        );
      } else if (value === 7) {
        onOpenNetworkModal();
      } else if (value === 1) {
        navigate(routes.account.details.root);
      } else if (value === 2) {
        navigate(routes.projects.create.root);
      } else if (value === 3) {
        disconnect();
        navigate(routes.root);
      } else if (value === 5) {
        navigate(routes.admin.root);
      } else if (value === 4) {
        dispatch(
          updateUserState({
            account: {
              email: '',
              password: '',
            },
            key: '',
          }),
        );
        navigate(routes.root);
        toast.info(notifyText.signOut.successful);
      }
    },
    [disconnect, dispatch, navigate, onOpenNetworkModal],
  );

  return handleDropdownChange;
};
