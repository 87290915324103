import { notifyText } from 'config/constants';
import { toast } from 'react-toastify';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import apiActions from 'store/api/actions';
import projectSelector from 'store/projects/selectors';
import userSelector from 'store/user/selectors';
import { contractsConfig, ContractsNames } from 'config';
import { changeProjectStatus } from '../actions';
import actionTypes from '../actionTypes';

export function* changeProjectStatusSaga({
  type,
  payload: { web3Provider, onSuccess, status },
}: ReturnType<typeof changeProjectStatus>) {
  yield put(apiActions.request(type));
  const myAddress = yield select(userSelector.getProp('address'));
  const network = yield select(userSelector.getProp('network'));
  const chainType = yield select(userSelector.getProp('chainType'));
  const { pid, projectName } = yield select(projectSelector.getProject('currentProject'));

  const { abi: tokenAbi, address: tokenAddress } = contractsConfig.contracts[ContractsNames.totopad][chainType];

  try {
    const tokenContract = yield new web3Provider.eth.Contract(tokenAbi, tokenAddress[network]);

    if (myAddress) {
      yield call(tokenContract.methods.changeProjectStatus(+pid, status).send, {
        from: myAddress,
        to: tokenAddress[network],
      });
    }

    toast.success(notifyText.approveProject.success(projectName));
    yield put(apiActions.success(type));
    onSuccess();
  } catch (err) {
    console.log(err);

    toast.success(notifyText.approveProject.error);
    yield put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.CHANGE_PROJECT_STATUS, changeProjectStatusSaga);
}
