import { Typography } from 'components';

/* eslint-disable react/no-unescaped-entities */
export const disclaimerSteps = {
  1: {
    title: 'Disclaimer / Terms of Service',
    subtitle: 'Investment Disclaimer:',
    content:
      'Launching hand-picked high-quality projects on the Blockchain.TotoPad is a decentralized multi-chain fundraising platform enabling projects to raise capital and promise safety to early stage investors. Stake TOTO tokens to get priority-access to promising projects.',
  },
  2: {
    title: 'Shield Yourself from Scammers',
    subtitle: '',
    content: (
      <ol>
        <li>
          1) Protect yourself from any scammers. Always double check the identity of the person you're speaking with.
        </li>
        <li>2) Never share private keys or wallet passwords with anyone, not even official TotoPad staff.</li>
        <li>
          3) Do not fill out Google forms or web forms unless an official TotoPad staff or admin confirms this is the
          correct URL
        </li>
        <li>
          4) Do not share private information (sensitive info) outside of interactions with the official Support Team,
        </li>
      </ol>
    ),
  },
  3: {
    title: 'Understand the Risks of Your Investment',
    subtitle: '',
    content: (
      <ol>
        <li>
          1) Cryptocurrencies are volatile by nature. Only invest what you can afford to lose, and do your own research.
        </li>
        <li>
          2 )Allocation on TotoPad is guaranteed by TOTO staking (time + amount % of allocation earned), and by paying
          before the payment window closes
        </li>
        <li>3) Accepted payment currencies dependent on project target raise</li>
        <li>
          4) Your investment can lead to a loss especially if markets are irrational. TotoPad is not responsible for
          losses or profits as result of your investment in an Allocation Mining Event.
        </li>
      </ol>
    ),
  },
  4: {
    title: 'Keep Communications On Our Official Telegram',
    subtitle: '',
    content: (
      <ol>
        <li>
          <Typography type="p" color="gray" size="f16">
            1) Where to find us on Telegram:
          </Typography>
          <div>
            <Typography type="p" color="gray" size="f16">
              Main:
              <a href="/" target="_blank" rel="noreferrer">
                @TOTOPAD
              </a>
            </Typography>
            <Typography type="p" color="gray" size="f16">
              Announcements:
              <a href="/" target="_blank" rel="noreferrer">
                @totopadAnnouncements
              </a>
            </Typography>
            <Typography type="p" color="gray" size="f16">
              Support:
              <a href="/" target="_blank" rel="noreferrer">
                @totopadSupportOfficial
              </a>
            </Typography>
          </div>
        </li>
        <li>
          <Typography type="p" color="gray" size="f16">
            2) Our admins will never DM you first. Always reach out to them (in-chat, directly). Look for this label
            next to their
          </Typography>
          <Typography type="p" color="gray" size="f16">
            names: "admin". "chat mod" "support"
          </Typography>
        </li>
        <li>
          <ol>
            <li>3) Email support: support@totopad</li>
            <li>Marketing offers: marketing @totopad</li>
            <li>Press: pr@totopad</li>
          </ol>
        </li>
      </ol>
    ),
  },
};
