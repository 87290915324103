import { bsc, target } from 'assets/img/section';

export const advertText =
  'Launching hand-picked high-quality projects on the Blockchain.TotoPad is a decentralized multi-chain fundraising platform enabling projects to raise capital and promise safety to early stage investors. Stake TOTO tokens to get priority-access to promising projects.';

export const raiseItem = {
  img: bsc,
  name: 'Binance Smart Chain',
};
export const distrItem = {
  img: bsc,
  name: 'Binance Smart Chain',
};
export const targetItem = {
  img: target,
  name: '500k USDT',
};
