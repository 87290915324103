import { useMemo, VFC } from 'react';
import { ContentCardProps, RequestStatus } from 'types';
import { ContentCard, NoProjectsCard, SkeletonCard } from 'containers/Cards';
import { useShallowSelector } from 'hooks';
import uiSelector from 'store/ui/selectors';
import actionTypesProjects from 'store/projects/actionTypes';
import cn from 'clsx';
import s from './styles.module.scss';

export interface ProjectCardsProps {
  className?: string;
  projects: ContentCardProps[];
}

export const ProjectCards: VFC<ProjectCardsProps> = ({ className, projects }) => {
  const { [actionTypesProjects.GET_ALL_PROJECTS]: projectGettingRequest } = useShallowSelector(uiSelector.getUI);

  const isGettingProjects = useMemo(() => projectGettingRequest === RequestStatus.REQUEST, [projectGettingRequest]);

  return (
    <div className={cn(s.projectCards, className)}>
      <div className={s.container}>
        {!!projects.length &&
          projects.map((project) => <ContentCard key={project.id} data={project} className={s.cardItem} />)}
        {isGettingProjects &&
          // eslint-disable-next-line react/no-array-index-key
          new Array(6).fill('').map((_, index) => <SkeletonCard key={String(index)} className={s.cardItem} />)}
      </div>
      {!projects.length && !isGettingProjects && <NoProjectsCard />}
    </div>
  );
};
