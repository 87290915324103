import { VFC } from 'react';

import clsx from 'clsx';

import { Typography } from 'components/Typography';
import { warning } from 'assets/img/section';
import s from './styles.module.scss';

export interface WarningProps {
  text: string;
  className?: string;
  color?: 'orange-dark' | 'blue';
  size?: 'md' | 'fc';
}

export const Warning: VFC<WarningProps> = ({ className, text, color = 'orange-dark', size = 'md' }) => {
  return (
    <div className={clsx(s.warning, className, s[color], s[size])}>
      <img src={warning} alt="warning" />
      <Typography size="f16">{text}</Typography>
    </div>
  );
};
