import { PropsWithChildren, VFC } from 'react';

import cn from 'clsx';

import s from './styles.module.scss';

export interface CardBoxProps {
  className?: string;
}

export const CardBox: VFC<PropsWithChildren<CardBoxProps>> = ({ className, children }) => {
  return <div className={cn(s.cardBox, className)}>{children}</div>;
};
