import { VFC } from 'react';

import cn from 'clsx';

import { spinner } from 'assets/img/section';
import s from './styles.module.scss';

export interface SpinnerProps {
  size?: 'sm' | 'lg';
  className?: string;
}

export const Spinner: VFC<SpinnerProps> = ({ size = 'sm', className }) => {
  return (
    <>
      {size === 'lg' ? (
        <div className={s.preloader}>
          <img src={spinner} alt="spinner" className={cn(s.spinner, s[size], className)} />
        </div>
      ) : (
        <img src={spinner} alt="spinner" className={cn(s.spinner, className)} />
      )}
    </>
  );
};
