import { useMemo, VFC } from 'react';
import { AccountParams, Button, CurrentNetwork, Modal, Select } from 'components';
import { IControlsPartProps, Modals } from 'types';
import { NetworkSelect } from 'containers/NetworkSelect';
import s from '../styles.module.scss';
import { networkitems, selectOptionItems } from '../HeaderControls.helper';

export const LoginPart: VFC<IControlsPartProps> = ({
  address,
  userKey,
  network,
  isAdmin,
  isMobile,
  currIcon,
  tokenBalance,
  isNetworkModalVisible,
  handleNetworkOpen,
  handleNetworkClose,
  onOpenModal,
  onChangeNetwork,
  onDropDownChange,
}) => {
  const options = useMemo(() => {
    if (isMobile) {
      return selectOptionItems.mobileOptions(!!userKey, tokenBalance, {
        onOpen: handleNetworkOpen,
        network,
        currIcon,
      });
    }
    return selectOptionItems.desktopOptions(isAdmin);
  }, [currIcon, handleNetworkOpen, isAdmin, isMobile, network, tokenBalance, userKey]);

  return (
    <div className={s.rowGridLogin}>
      {!isMobile && (
        <>
          <CurrentNetwork onOpen={handleNetworkOpen} currIcon={currIcon} network={network} className={s.item} />
          <AccountParams address={address} className={s.item} />

          <div className={s.itemBtn}>
            <Button href="/signup" size="ssm">
              Create Account
            </Button>
            {/* </div> */}
            {/* <div className={s.itemBtn}> */}
            <Button size="ssm" onClick={() => onOpenModal(Modals.SignIn)}>
              Sign In
            </Button>
          </div>
        </>
      )}

      <div className={s.item}>
        <Select
          allowToChange={false}
          options={options}
          defaultOption={selectOptionItems.defaultOption}
          onSelect={onDropDownChange}
        />
      </div>

      {isNetworkModalVisible && (
        <Modal
          open={isNetworkModalVisible}
          onClose={handleNetworkClose}
          className={s.modal}
          wrapClassName={s.modalWrapper}
          closable
          closeIcon={false}
        >
          <NetworkSelect data={networkitems} closeModal={handleNetworkClose} onChangeNetwork={onChangeNetwork} />
        </Modal>
      )}
    </div>
  );
};
