import { AccountProps, IInputFormikProps } from 'types';
import * as Yup from 'yup';

export const resetInitialState: Partial<AccountProps> = {
  email: '',
};

export const resetValidationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email format').min(5).required('Incorrect notation'),
});

export const resetFormConfig: IInputFormikProps[] = [
  {
    id: 'email',
    name: 'email',
    renderProps: {
      label: 'Email address',
      name: 'email',
      type: 'text',
      placeholder: 'Enter e-mail',
    },
  },
];
