import { logoMetamask, logoWallet } from 'assets/img/section';
import { Button, Typography } from 'components';
import { useCallback, useState, VFC } from 'react';
import { Chains, INotifyModalProps, WalletProviders } from 'types';

import s from '../styles.module.scss';

interface IConnectWalletModalProps extends INotifyModalProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onConnectWallet: (provider: any, newChain: any) => void;
  initConnectToRequiredChain: () => void;
}

export const ConnectWalletModal: VFC<IConnectWalletModalProps> = ({
  data,
  onConnectWallet,
  closeModal,
  initConnectToRequiredChain,
}) => {
  const [showAllInfo, setShowAllInfo] = useState(false);

  const handleShowAllInfo = useCallback(() => {
    setShowAllInfo(!showAllInfo);
  }, [showAllInfo]);

  return (
    <div className={s.connect}>
      <Typography type="h3" color="white">
        {data?.title}
      </Typography>
      <Typography type="span" color="gray">
        Please select a wallet to connect to this dapp:
      </Typography>
      <Button
        icon={logoMetamask}
        color="outline"
        size="slg"
        className={s.connectBtn}
        onClick={() => {
          initConnectToRequiredChain();
          closeModal();
        }}
      />
      <Button
        icon={logoWallet}
        rowReverse
        color="outline-blue"
        size="slg"
        className={s.connectBtn}
        onClick={() => onConnectWallet(WalletProviders.walletConnect, Chains.bsc)}
      >
        <Typography color="white" size="f22">
          Wallet Connect
        </Typography>
      </Button>
      <Button color="back-none" onClick={handleShowAllInfo}>
        <Typography color="red" size="f16" border>
          What is a wallet ?
        </Typography>
      </Button>
      {showAllInfo && (
        <Typography type="p" color="gray">
          {data?.content}
          <a href="/" target="_blank" rel="noreferrer">
            <Typography type="span" color="red" border>
              this explanation.
            </Typography>
          </a>
        </Typography>
      )}
    </div>
  );
};
