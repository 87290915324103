import { put, takeLatest, call } from 'redux-saga/effects';
import apiActions from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import actionTypes from '../actionTypes';
import { getUserInfo } from '../actions';
import { updateUserState } from '../reducer';

export function* getUserInfoSaga({ type }: ReturnType<typeof getUserInfo>) {
  yield put(apiActions.request(type));
  try {
    const {
      data: { name, email },
    } = yield call(baseApi.getUserInfo);

    yield put(
      updateUserState({
        account: {
          name,
          email,
        },
      }),
    );
    yield put(apiActions.success(type));
  } catch (err) {
    console.log(err);
    yield put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_USER_INFO, getUserInfoSaga);
}
