import { IFilterItemProps } from 'pages/FiltersPage/FiltersPage.type';
import { ChainsEnum } from 'types';

export const raisFilterItems: IFilterItemProps[] = [
  {
    value: 1,
    text: 'Show all',
  },
  {
    value: 2,
    text: ChainsEnum['Binance-Smart-Chain'],
  },
  {
    value: 3,
    text: ChainsEnum.Ethereum,
  },
  {
    value: 4,
    text: ChainsEnum.Avalanche,
  },
];

export const raisFilterTags = [
  {
    value: 1,
    text: 'all',
  },
  {
    value: 2,
    text: 'binance',
  },
  {
    value: 3,
    text: 'ethereum',
  },
  {
    value: 4,
    text: 'avalanche',
  },
];
