import { VFC } from 'react';

import cn from 'clsx';

import { Button } from 'components/Button';
import { Typography } from 'components/Typography';
import { bsc } from 'assets/img/section';
import { ChainsEnum } from 'types';
import s from './styles.module.scss';

export interface CurrentNetworkProps {
  className?: string;
  network: string;
  currIcon: string;
  onOpen: () => void;
  mobile?: boolean;
}

export const CurrentNetwork: VFC<CurrentNetworkProps> = ({ network, currIcon, onOpen, className, mobile = false }) => {
  return (
    <Button color="back-none" size="icon" className={cn(s.currentNetwork, className)} onClick={onOpen}>
      {!mobile && (
        <>
          <div className={s.title}>
            <Typography type="span" color="gray" size="f12" uppercase>
              Current Network
            </Typography>
            {(network === ChainsEnum.Avalanche || network === ChainsEnum['Binance-Smart-Chain']) && (
              <Typography type="span" color="gray" size="f12" uppercase>
                Testnet
              </Typography>
            )}
          </div>
        </>
      )}
      <Typography type="span" color="white">
        <img src={currIcon || bsc} alt="icon" />
        <div>
          {network}
          {mobile && (
            <>
              {(network === ChainsEnum.Avalanche || network === ChainsEnum['Binance-Smart-Chain']) && (
                <Typography type="span" color="gray" size="f12" uppercase>
                  Testnet
                </Typography>
              )}
            </>
          )}
        </div>
      </Typography>
    </Button>
  );
};
