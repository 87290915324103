import { Modals } from 'types/store/modals';

export const modalData = {
  [Modals.ConnectWallet]: {
    title: 'Connect a Wallet',
    content:
      'Wallets are used to send, receive, and store digital assets like Ether. Wallets come in many forms. They are either built into your browser, an extension added to your browser, a piece of hardware plugged into your computer or even an app on your phone. For more information about wallets, see ',
  },

  [Modals.SelectWallet]: {
    title: 'ConnectWallet',
  },

  [Modals.ResetPassword]: {
    title: 'Reset Password',
  },

  [Modals.SignIn]: {
    title: 'Sign In',
  },

  [Modals.LoginAndAuth]: {
    title: 'Login and Authorize Your Wallet',
    subtitle:
      'This dapp requires access to your wallet, please login and authorize access to your MetaMask accounts to continue.',
    warningText: 'This dapp requires access to your account information.',
  },

  // [Modals.ApprovePending]: {
  //   title1: 'STEP 1/2',
  //   title2: 'APPROVE',
  //   icon: '',
  //   title3: 'Please press "Approve" button in metamask extension',
  //   title4:
  //     'BEP-20 tokens are deployed with functionality that allows other smart contracts to move tokens. By approving the smart contracts, it now has permission to execute the peer to peer swapping behavior on your behalf. The Spend Limit permission is the total amount of tokens that are able to move when using MetaMask Swap.',
  // },

  // [Modals.ApproveRejected]: {
  //   title1: 'STEP 1/2',
  //   title2: 'APPROVE',
  //   icon: '',
  //   title3:
  //     'You rejected Approve transaction in Metamask. Press Approve again to start over or close this window.',
  //   title4:
  //     'BEP-20 tokens are deployed with functionality that allows other smart contracts to move tokens. By approving the smart contracts, it now has permission to execute the peer to peer swapping behavior on your behalf. The Spend Limit permission is the total amount of tokens that are able to move when using MetaMask Swap.',
  // },

  // [Modals.SendPending]: {
  //   title1: 'STEP 2/2',
  //   title2: 'SEND',
  //   icon: '',
  //   title3: 'Please press "Confirm" button in Metamask extension',
  //   title4: 'Your assets will be transferred from your wallet to the contract address',
  // },

  // [Modals.SendSuccess]: {
  //   title1: 'STEP 2/2',
  //   title2: 'SEND',
  //   icon: '',
  //   title3: 'Sent',
  //   title4: 'It takes some time for transaction to get confirmed.',
  // },

  // [Modals.SendRejected]: {
  //   title1: 'STEP 2/2',
  //   title2: 'SEND',
  //   icon: '',
  //   title3: '',
  //   title4: 'It takes some time for transaction to get confirmed.',
  //   title5:
  //     'You rejected Send transaction in Metamask. Press Send again to start over or close this window.',
  // },
};
