import { avax, bsc, eth } from 'assets/img/section';
import { ChainsEnum } from 'types';

export const notifyText = {
  disconnet: {
    info: 'Disconnect from wallet ',
  },
  signIn: {
    successful: 'Successfully logged in',
    error: 'Error when trying to login',
  },
  signOut: {
    successful: 'Logged out',
  },
  resetPassword: {
    info: 'Check your email to generate new password',
    error: 'Error update password',
  },
  updateInfo: {
    success: (key: string) => `Successful updating ${key}`,
  },
  createProject: {
    success: (name: string) => `Successful creating project ${name}`,
    error: 'Error creating project',
  },
  rejectProject: {
    success: (name: string) => `Successful reject project ${name}`,
    error: 'Error reject project',
  },
  approve: {
    success: 'Success approve',
    error: 'Error approve',
  },
  approveProject: {
    success: (name: string) => `Success approve new project ${name}`,
    error: 'Error approve new project',
  },
  stake: {
    success: 'Successful stake',
    error: 'Error stake',
  },
  fund: {
    success: 'Successful fund',
    error: 'Error fund',
  },
  withdraw: {
    success: 'Successful withdraw',
    error: 'Error withdraw',
  },
  claim: {
    success: 'Successful claim',
    error: 'Error claim',
  },
  createPost: {
    success: 'Successful creating post',
    error: 'Error create',
  },
  deletePost: {
    success: 'Successful delete',
    error: 'Error delete',
  },
};

export const networkIcons: { [k: string]: string } = {
  [ChainsEnum['Binance-Smart-Chain']]: bsc,
  [ChainsEnum.Ethereum]: eth,
  [ChainsEnum.Avalanche]: avax,
};
