import { fork } from 'redux-saga/effects';

import getAllProjects from './getAllProjectsSaga';
import getUserProjects from './getUserProjectsSaga';
import createProject from './createProjectSaga';
import getCurrentProject from './getCurrentProjectSaga';
import signingProject from './signingProjectSaga';
import changeProjectStatusSaga from './changeProjectStatusSaga';
import getProjectStatusSaga from './getProjectStatusSaga';
import stake from './stakeSaga';
import fund from './fundSaga';
import withdraw from './withdrawSaga';
import withdrawOwner from './withdrawOwner';
import claimReward from './claimRewardSaga';
import getTotalFilterProjects from './getTotalFilterProjectsSaga';

export default function* userSagas() {
  yield fork(stake);
  yield fork(fund);
  yield fork(withdraw);
  yield fork(withdrawOwner);
  yield fork(claimReward);

  yield fork(getTotalFilterProjects);
  yield fork(getAllProjects);
  yield fork(getUserProjects);
  yield fork(getCurrentProject);

  yield fork(createProject);
  yield fork(signingProject);
  yield fork(changeProjectStatusSaga);
  yield fork(getProjectStatusSaga);
}
