/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { disconnectWalletState, updateUserState } from 'store/user/reducer';
import userSelector from 'store/user/selectors';
import { Subscription } from 'rxjs';
import { useShallowSelector } from 'hooks';
import { ChainIds, Chains, requiredChaiIds, State, UserState, WalletProviders } from 'types';
import { shortenPhrase } from 'utils';
import { notifyText } from 'config/constants';
import { clearBalanceState } from 'store/balances/reducer';
import { Window } from 'types/globals';
import { WalletService } from '../walletService';

interface IContextValue {
  connect: (provider: WalletProviders, chain: Chains) => Promise<void>;
  disconnect: () => void;
  walletService: WalletService;
  initConnectToRequiredChain: () => void;
}

const Web3Context = createContext({} as IContextValue);

const WalletConnectContext: FC = ({ children }) => {
  const [currentSubsriber, setCurrentSubsciber] = useState<Subscription>();
  const WalletConnect = useMemo(() => new WalletService(), []);
  const dispatch = useDispatch();
  const {
    address,
    provider: WalletProvider,
    chainType,
    network,
  } = useShallowSelector<State, UserState>(userSelector.getUser);

  const disconnect = useCallback(() => {
    dispatch(disconnectWalletState());
    dispatch(clearBalanceState());
    WalletConnect.resetConnect();
    currentSubsriber?.unsubscribe();
    toast.info(notifyText.disconnet.info);
  }, [WalletConnect, currentSubsriber, dispatch]);

  const subscriberSuccess = useCallback(
    (res: any) => {
      if (document.visibilityState !== 'visible') {
        disconnect();
      }
      if (res.name === 'accountsChanged') {
        disconnect();
        // dispatch(login({ address: res.address, web3Provider: WalletConnect.Web3() }));
        toast.info('Please sign login message at MetaMask');
      }
    },
    [disconnect],
  );

  const subscriberError = useCallback(
    (err: any) => {
      console.error(err);
      if (err.code !== 4) {
        WalletConnect.resetConnect();
        toast.error('You changed to wrong network. Please choose Binance-Smart-Chain');
        dispatch(disconnectWalletState());
      }
    },
    [WalletConnect, dispatch],
  );

  const connect = useCallback(
    async (provider: WalletProviders, chain: Chains) => {
      const connected = await WalletConnect.initWalletConnect(provider, chain, chainType);
      if (connected) {
        try {
          const sub = WalletConnect.eventSubscribe().subscribe(subscriberSuccess, subscriberError);
          const accountInfo: any = await WalletConnect.getAccount();
          if (accountInfo.address) {
            dispatch(
              updateUserState({
                provider: accountInfo.type,
                address: accountInfo.address,
                network: chain,
              }),
            );
            toast.success(`Wallet connected: ${shortenPhrase(accountInfo.address, 5, 5)}`);
          }

          setCurrentSubsciber(sub);
        } catch (error: any) {
          console.log(error);
          // metamask doesn't installed,
          // redirect to download MM or open MM on mobile
          if (error.code === 4) {
            window.open(
              `https://metamask.app.link/dapp/${window.location.hostname + window.location.pathname}/?utm_source=mm`,
            );
          }
        }
      }
    },
    [WalletConnect, chainType, dispatch, subscriberError, subscriberSuccess],
  );

  const initConnectToRequiredChain = useCallback(async () => {
    try {
      const chainId = await ((window as Window).ethereum as any).request({ method: 'eth_chainId' });
      await WalletConnect.initWalletConnect(WalletProviders.metamask, Chains.eth, 'mainnet');

      if (requiredChaiIds.includes(chainId)) {
        connect(WalletProviders.metamask, ChainIds[chainId]);
      } else {
        connect(WalletProviders.metamask, Chains.eth);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  }, [WalletConnect, connect]);

  useEffect(() => {
    // connect user if he connected previously
    if (WalletProvider && !address.length) {
      connect(WalletProviders.metamask, network);
    }
  }, [WalletProvider, address.length, connect, network]);

  return (
    <Web3Context.Provider value={{ connect, disconnect, walletService: WalletConnect, initConnectToRequiredChain }}>
      {children}
    </Web3Context.Provider>
  );
};

const useWalletConnectorContext = () => useContext(Web3Context);

export { WalletConnectContext, useWalletConnectorContext };
