import ui from './ui/reducer';
import modals from './modals/reducer';
import user from './user/reducer';
import projects from './projects/reducer';
import balances from './balances/reducer';
import posts from './posts/reducer';
/* PLOP_INJECT_IMPORT_REDUCER */

export default {
  ui,
  user,
  modals,
  projects,
  balances,
  posts,
  /* PLOP_INJECT_PLACE_REDUCER */
};
