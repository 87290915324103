import { notifyText } from 'config/constants';
import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';
import apiActions from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import { getEncodeMedia } from 'utils';
import { createProject } from '../actions';
import actionTypes from '../actionTypes';

export function* createProjectSaga({ type, payload: { data, onSuccess } }: ReturnType<typeof createProject>) {
  yield put(apiActions.request(type));

  try {
    const allocStartTime = new Date(data.allocStartTime).getTime() / 1000;
    const stakingEndTime = new Date(data.stakingEndTime).getTime() / 1000;
    const fundingEndTime = new Date(data.fundingEndTime).getTime() / 1000;
    const rewardStopTime = new Date(data.rewardStopTime).getTime() / 1000;

    const logo = yield getEncodeMedia(data.logo);
    const photo = yield getEncodeMedia(data.photo);
    const banner = yield getEncodeMedia(data.banner);

    const team = [];
    for (let i = 0; i < data.team.length; i += 1) {
      const photo = yield call(getEncodeMedia, data.team[i].photo);
      team.push({
        ...data.team[i],
        photo,
      });
    }

    const fomatedData = JSON.stringify({
      ...data,
      logo,
      photo,
      banner,
      team,
      allocStartTime,
      stakingEndTime,
      fundingEndTime,
      rewardStopTime,
    });
    yield call(baseApi.createProject, fomatedData);

    toast.success(notifyText.createProject.success(data.projectName));
    onSuccess();
    yield put(apiActions.success(type));
  } catch (err) {
    console.log(err);

    toast.success(notifyText.createProject.error);
    yield put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.CREATE_PROJECT, createProjectSaga);
}
