import { VFC } from 'react';

import cn from 'clsx';

import s from './styles.module.scss';

export interface FormBorderProps {
  className?: string;
}

export const FormBorder: VFC<FormBorderProps> = ({ className }) => {
  return (
    <div className={cn(s.formBorder, className)}>
      <div />
    </div>
  );
};
