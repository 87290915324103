/* eslint-disable @typescript-eslint/no-explicit-any */
import { call, put, select, takeLatest } from 'redux-saga/effects';
import apiActions from 'store/api/actions';
import userSelector from 'store/user/selectors';
import balancesSelector from 'store/balances/selectors';
import { contractsConfig, ContractsNames } from 'config';
import { toast } from 'react-toastify';
import { notifyText } from 'config/constants';
// import { updateBalancesState } from 'store/balances/reducer';
import { erc20Abi } from 'config/abi';
import balanceActionTypes from 'store/balances/actionTypes';
import { getBalanceInfoSaga } from 'store/balances/sagas/getBalanceInfoSaga';
import { approveSaga } from '../../user/sagas/approveSaga';
import actionTypes from '../actionTypes';
import userActionTypes from '../../user/actionTypes';
import { stake } from '../actions';

export function* fundSaga({ type, payload: { web3Provider, pid, activeTab } }: ReturnType<typeof stake>) {
  yield put(apiActions.request(type));
  const myAddress = yield select(userSelector.getProp('address'));
  const network = yield select(userSelector.getProp('network'));
  const chainType = yield select(userSelector.getProp('chainType'));
  const fundingBalance = yield select(balancesSelector.getProp('fundingBalance'));
  const activePidValue = pid * 3 + activeTab;

  const { abi: totopadAbi, address: stakingAddress } = contractsConfig.contracts[ContractsNames.totopad][chainType];
  const { address: totoroAddress } = contractsConfig.contracts[ContractsNames.fundingToken][chainType];
  const { address: fundingTokenAddress } = contractsConfig.contracts[ContractsNames.fundingToken][chainType];

  try {
    const totopadContract = yield new web3Provider.eth.Contract(totopadAbi, stakingAddress[network]);
    const fundingTokenContract = yield new web3Provider.eth.Contract(erc20Abi as any, fundingTokenAddress[network]);

    const decimalsFundingToken = yield call(fundingTokenContract.methods.decimals().call);

    if (myAddress) {
      yield call(approveSaga, {
        type: userActionTypes.APPROVE,
        payload: {
          web3Provider,
          spender: totoroAddress[network],
          tokenAddress: stakingAddress[network],
          amount: fundingBalance,
          decimals: decimalsFundingToken,
        },
      });
      yield call(totopadContract.methods.fundPledge(activePidValue).send, {
        from: myAddress,
        to: stakingAddress[network],
      });

      yield call(getBalanceInfoSaga, {
        type: balanceActionTypes.GET_BALANCES_INFO,
        payload: {
          web3Provider,
          activeLimit: activeTab,
        },
      });
      // yield put(
      //   updateBalancesState({
      //     haveFundation: true,
      //   }),
      // );
    }

    toast.success(notifyText.fund.success);
    yield put(apiActions.success(type));
  } catch (err) {
    console.log(err);
    toast.error(notifyText.fund.error);
    yield put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.FUND, fundSaga);
}
