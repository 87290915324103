import { useCallback, useEffect, useMemo, useRef, VFC } from 'react';
import { Field, Form, Formik } from 'formik';
import { AccountParams, Button, InputWrapper, Typography } from 'components';
import { AccountProps, INotifyModalProps, Modals, RequestStatus, State, UserState } from 'types';
import { useShallowSelector } from 'hooks';
import { useDispatch } from 'react-redux';
import { signIn } from 'store/user/actions';
import uiSelector from 'store/ui/selectors';
import userSelector from 'store/user/selectors';
import actionTypes from 'store/user/actionTypes';
import { useWalletConnectorContext } from 'services';
import { useNavigate } from 'react-router-dom';
import { signInFormConfig, signInInitialvalues, signInValidationSchema } from './SignIn.helper';
import s from '../styles.module.scss';

interface ISignInModalProps extends INotifyModalProps {
  onOpenResetModal: (type: Modals) => void;
}

export const SignInModal: VFC<ISignInModalProps> = ({ data, closeModal, onOpenResetModal }) => {
  const formikRef = useRef(null);
  const { walletService } = useWalletConnectorContext();
  const { [actionTypes.SIGN_IN]: signInRequestStatus } = useShallowSelector(uiSelector.getUI);
  const { address, key } = useShallowSelector<State, UserState>(userSelector.getUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isSignInLoading = useMemo(() => signInRequestStatus === RequestStatus.REQUEST, [signInRequestStatus]);
  const isSignInSuccess = useMemo(() => signInRequestStatus === RequestStatus.SUCCESS, [signInRequestStatus]);

  const handleSubmit = useCallback(
    (data: Partial<AccountProps>) => {
      const { email, password } = data;
      dispatch(
        signIn({
          email,
          password,
          web3Provider: walletService.Web3(),
        }),
      );
    },
    [dispatch, walletService],
  );

  const handleMoveToSignup = () => {
    navigate('/signup');
    closeModal();
  };

  useEffect(() => {
    if (key.length) {
      // navigate('/');
      closeModal();
    }
  }, [closeModal, isSignInSuccess, key.length, navigate, signInRequestStatus]);

  return (
    <Formik
      innerRef={formikRef}
      enableReinitialize
      initialValues={signInInitialvalues}
      validationSchema={signInValidationSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, values, handleChange, handleBlur, handleSubmit, isValid, dirty }) => (
        <Form translate={undefined} className={s.signIn}>
          <div className={s.signInTitle}>
            <Typography type="h3" color="white">
              {data?.title}
            </Typography>
            <AccountParams address={address} />
          </div>

          {signInFormConfig.map(({ id, name, renderProps }) => (
            <Field id={id} name={name} key={id}>
              {() => (
                <InputWrapper
                  id={id}
                  type={renderProps.type}
                  title={renderProps.label}
                  value={values[name]}
                  placeholder={renderProps.placeholder}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors[name] && touched[name] ? errors[name] : undefined}
                  className={s.signInInput}
                  autoComplite="on"
                />
              )}
            </Field>
          ))}
          <Button color="back-none" className={s.signInForgot} onClick={() => onOpenResetModal(Modals.ResetPassword)}>
            <Typography type="span" color="red" size="f14">
              Forgotten password ?
            </Typography>
          </Button>
          <Button
            type="submit"
            onClick={handleSubmit}
            disabled={!isValid || !dirty}
            color="outline-green"
            className={s.signInSubmit}
            isLoading={isSignInLoading}
          >
            Sign In
          </Button>
          <div className={s.signInBottom}>
            <Typography type="span">Haven&rsquo;t registered?</Typography>
            <Button color="back-none" onClick={handleMoveToSignup} className={s.signInBottomBtn}>
              <Typography color="red" border>
                Create your free account here!
              </Typography>
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
