/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { IFilterItemProps } from 'pages/FiltersPage/FiltersPage.type';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getAllProjects } from 'store/projects/actions';
import { getQueryUrl } from 'utils';
import { updateProjectsState } from 'store/projects/reducer';

export const useChangeFilterQuery = (
  categories: any[],
): [IFilterItemProps, number, (filterItem: IFilterItemProps) => void, (page: number) => void] => {
  const [activeCategory, setActiveCategory] = useState<IFilterItemProps>(categories[0]);
  const [currentPage, setCurrentPage] = useState(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { stage } = useParams();
  const { search } = useLocation();
  const currUrl = new URLSearchParams(search);

  const handleChangeQuery = useCallback(
    (filterItem: IFilterItemProps, isPageChanged: boolean = false) => {
      const category = categories[filterItem.value - 1];
      const newUrl = getQueryUrl({
        sort: category.text,
        stage,
        page: isPageChanged ? currentPage.toString() : '1',
        size: '6',
      });
      if (!isPageChanged) {
        dispatch(updateProjectsState({ allProjects: [] }));
        setCurrentPage(1);
        setActiveCategory(category);
      }

      navigate(`/ido/${stage}/?${newUrl}`);
      dispatch(getAllProjects({ url: newUrl }));
    },
    [categories, currentPage, dispatch, navigate, stage],
  );

  useEffect(() => {
    const stageTag = currUrl.get('stage');
    if (stageTag === null || currentPage === 0) {
      setCurrentPage(1);
      handleChangeQuery(categories[0]);
    }
  }, [categories, currUrl, handleChangeQuery]);

  useEffect(() => {
    const sortTag = currUrl.get('sort');
    const pageTag = currUrl.get('page');
    const isContentChanged = sortTag !== activeCategory.text || +pageTag !== currentPage;

    if (!isContentChanged || sortTag === null || pageTag === null) {
      return;
    }

    if (sortTag !== activeCategory.text) {
      const currCategoryTag = categories.find(({ text }) => text.includes(sortTag));
      handleChangeQuery(currCategoryTag);
    }
    if (+pageTag < currentPage) {
      handleChangeQuery(activeCategory, true);
    }
  }, [activeCategory, categories, currUrl, currentPage, handleChangeQuery]);

  return [activeCategory, currentPage, handleChangeQuery, setCurrentPage];
};
