import { VFC } from 'react';

import cn from 'clsx';

import { CardBox, SkeletonLoader } from 'components';
import s from './styles.module.scss';

export interface SkeletonProgressCardProps {
  className?: string;
}

export const SkeletonProgressCard: VFC<SkeletonProgressCardProps> = ({ className }) => {
  return (
    <CardBox className={cn(s.card, className)}>
      <div className={s.container}>
        <div className={s.row}>
          <SkeletonLoader width="50px" />
          <SkeletonLoader width="50px" />
          <SkeletonLoader width="50px" />
        </div>
        <div className={s.row}>
          <SkeletonLoader width="70px" />
          <SkeletonLoader width="70px" />
          <SkeletonLoader width="70px" />
        </div>
        <div className={s.row}>
          <SkeletonLoader width="300px" height="50px" />
        </div>
      </div>
    </CardBox>
  );
};
