/* eslint-disable @typescript-eslint/no-explicit-any */
import { Chains, IChainType, IConnectWallet, IContracts } from 'types';

import { erc20Abi, totopadAbi } from './abi';

export const chains: {
  [key: string]: {
    [key: string]: {
      name: string;
      chainId: number;
      provider: {
        [key: string]: any;
      };
      img?: any;
    };
  };
} = {
  'Binance-Smart-Chain': {
    mainnet: {
      name: 'Binance-Smart-Chain', // for mainnet also will be testnet config
      chainId: 97,
      provider: {
        MetaMask: { name: 'MetaMask' },
        WalletConnect: {
          name: 'WalletConnect',
          useProvider: 'rpc',
          provider: {
            rpc: {
              rpc: {
                97: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
              },
              chainId: 97,
            },
          },
        },
      },
    },
    testnet: {
      name: 'Binance-Smart-Chain',
      chainId: 97,
      provider: {
        MetaMask: { name: 'MetaMask' },
        WalletConnect: {
          name: 'WalletConnect',
          useProvider: 'rpc',
          provider: {
            rpc: {
              rpc: {
                97: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
              },
              chainId: 97,
            },
          },
        },
      },
    },
  },
  Ethereum: {
    mainnet: {
      name: 'Ethereum',
      chainId: 1,
      provider: {
        MetaMask: { name: 'MetaMask' },
        WalletConnect: {
          name: 'WalletConnect',
          useProvider: 'rpc',
          provider: {
            rpc: {
              1: 'https://mainnet.infura.io/v3/4dd57d0bbe3f412689cb114d0735e990',
              chainId: 1,
            },
          },
        },
      },
    },
    testnet: {
      name: 'Kovan testnet',
      chainId: 42,
      provider: {
        MetaMask: { name: 'MetaMask' },
        WalletConnect: {
          name: 'WalletConnect',
          useProvider: 'rpc',
          provider: {
            rpc: {
              42: 'https://kovan.infura.io/v3/4dd57d0bbe3f412689cb114d0735e990',
              chainId: 42,
            },
          },
        },
      },
    },
  },
  Avalanche: {
    mainnet: {
      name: 'Avalanche Fuji Testnet', // for mainnet also will be testnet config
      chainId: 43113,
      provider: {
        MetaMask: { name: 'MetaMask' },
        WalletConnect: {
          name: 'WalletConnect',
          useProvider: 'rpc',
          provider: {
            rpc: {
              43113: 'https://api.avax-test.network/ext/bc/C/rpc',
              chainId: 43113,
            },
          },
        },
      },
    },
    testnet: {
      name: 'Avalanche Fuji Testnet',
      chainId: 43113,
      provider: {
        MetaMask: { name: 'MetaMask' },
        WalletConnect: {
          name: 'WalletConnect',
          useProvider: 'rpc',
          provider: {
            rpc: {
              43113: 'https://api.avax-test.network/ext/bc/C/rpc',
              chainId: 43113,
            },
          },
        },
      },
    },
  },
};

export const connectWallet = (newChainName: Chains, type: IChainType): IConnectWallet => {
  const chain = chains[newChainName][type];
  return {
    network: {
      chainName: chain.name,
      chainID: chain.chainId,
    },
    provider: chain.provider,
    settings: { providerType: true },
  };
};

export enum ContractsNames {
  totopad = 'totopad',
  totoro = 'totoro',
  fundingToken = 'fundingToken',
}

export type IContractsNames = keyof typeof ContractsNames;

export const contractsConfig: IContracts = {
  names: Object.keys(ContractsNames),
  decimals: 18,
  contracts: {
    [ContractsNames.totopad]: {
      testnet: {
        address: {
          [Chains.bsc]: '0x5dA277aB778742f4916B1282d487f2047eB7001A',
          [Chains.eth]: '0x405034f2885C65548eAC5EB0aa6DbB37800c5B5A',
          [Chains.avax]: '0x61eF868B71bD4dc8c5a5387f1816F8757da5097a',
        },
        abi: totopadAbi,
      },
      mainnet: {
        address: {
          [Chains.bsc]: '0x5dA277aB778742f4916B1282d487f2047eB7001A',
          [Chains.eth]: '0xD215304Bc3650B65DC7AeeE3893981DA14a35f58',
          [Chains.avax]: '0x61eF868B71bD4dc8c5a5387f1816F8757da5097a',
        },
        abi: totopadAbi,
      },
    },

    [ContractsNames.totoro]: {
      testnet: {
        address: {
          [Chains.bsc]: '0x06F67827684b3aC999aa5f96D9Eb30476aE26266',
          [Chains.eth]: '0x242979299AC9ebC4059a5Df7A8B54738F06Db896',
          [Chains.avax]: '0x502c11C15BF18F3CCE7b5df54A8D85DdAAe35e2F',
        },
        abi: erc20Abi,
      },
      mainnet: {
        address: {
          [Chains.bsc]: '0x06F67827684b3aC999aa5f96D9Eb30476aE26266',
          [Chains.eth]: '0xfc09c7cFD9c175DD9423ca02aE1249579AB12F12',
          [Chains.avax]: '0x502c11C15BF18F3CCE7b5df54A8D85DdAAe35e2F',
        },
        abi: erc20Abi,
      },
    },
    [ContractsNames.fundingToken]: {
      testnet: {
        address: {
          [Chains.bsc]: '0x514b5EC40DF8e051dcD2f0bD58411c466528BD08',
          [Chains.eth]: '0xfabafe6D746d4C0FD53B522B79A9A017339c128e',
          [Chains.avax]: '0x31667a75770aFcB34EC098D96f8f114Ce69319DC',
        },
        abi: erc20Abi,
      },
      mainnet: {
        address: {
          [Chains.bsc]: '0x514b5EC40DF8e051dcD2f0bD58411c466528BD08',
          [Chains.eth]: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
          [Chains.avax]: '0x31667a75770aFcB34EC098D96f8f114Ce69319DC',
        },
        abi: erc20Abi,
      },
    },
  },
};
