import { fork } from 'redux-saga/effects';

import getPosts from './getPostsSaga';
import createPost from './createPostSaga';
import deletePost from './deletePostSaga';

export default function* postsSaga() {
  yield fork(getPosts);
  yield fork(createPost);
  yield fork(deletePost);
}
