import URL from 'appConstants/URL';
import { LoginReq } from 'types';
import { CreatePostReq, ResetPasswordReq, UpdateDataProps } from 'types/requests';
import ajax from './ajax';

export const baseApi = {
  metamaskSignIn(data: LoginReq) {
    return ajax({
      method: 'post',
      url: URL.metamaskSignIn,
      data,
    });
  },
  metamaskSignUp(data: LoginReq) {
    return ajax({
      method: 'post',
      url: URL.metamaskSignUp,
      data,
    });
  },
  resetPassword(data: ResetPasswordReq) {
    return ajax({
      method: 'post',
      url: URL.resetPassword,
      data,
    });
  },
  updateUserInfo(data: UpdateDataProps) {
    return ajax({
      method: 'post',
      url: URL.updateUserInfo,
      data,
    });
  },
  getUserInfo() {
    return ajax({
      method: 'get',
      url: URL.getUserInfo,
    });
  },
  createProject(data: string) {
    return ajax({
      method: 'post',
      url: URL.createProject,
      data,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },
  gerCurrentProject(id: string) {
    return ajax({
      method: 'get',
      url: URL.getCurrentProject(id),
    });
  },
  getAllProjects(url: string) {
    return ajax({
      method: 'get',
      url: URL.getAllProjects(url),
    });
  },
  getUserProject(address: string) {
    return ajax({
      method: 'get',
      url: URL.getUserProjects(address),
    });
  },
  // adminApprove(txHash: AdminApproveReq, id: string) {
  //   return ajax({
  //     method: 'post',
  //     url: URL.adminApprove(id),
  //     data: txHash,
  //   });
  // },
  // adminReject(id: string) {
  //   return ajax({
  //     method: 'post',
  //     url: URL.adminReject(id),
  //   });
  // },
  createPost(data: CreatePostReq) {
    return ajax({
      method: 'post',
      url: URL.createPost,
      data,
    });
  },
  deletePost(id: number) {
    return ajax({
      method: 'delete',
      url: URL.deletePost(id),
    });
  },
  getPosts() {
    return ajax({
      method: 'get',
      url: URL.getPosts,
    });
  },
};
