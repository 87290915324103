/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, InputWrapper, Typography } from 'components';
import { ChangeEvent, useMemo, VFC } from 'react';
import { ITabProps } from '../PoolFromCard.types';
import s from '../styles.module.scss';

export const StakingTab: VFC<ITabProps> = ({ value, handleChange, onStake, isLoading, balance, currentLimit }) => {
  const { userStaked, totalStaked, token } = balance;

  const maxStakeValue = useMemo((): number | undefined => {
    const { value } = currentLimit;

    if (value === 0) {
      return 250 - +userStaked;
    }
    if (value === 1) {
      return 1000 - +userStaked;
    }
    return undefined;
  }, [currentLimit, userStaked]);

  const handleSetMaxStake = () => {
    const limitLessThenBalance = +token - maxStakeValue;
    const limitMoreThenBalance = maxStakeValue - +token;
    if (maxStakeValue > 0 && limitLessThenBalance > 0) {
      // если моего баланса хвататет для стэйка
      handleChange(maxStakeValue.toString());
    } else if (maxStakeValue > 0 && limitMoreThenBalance > 0) {
      handleChange(token.toString());
    } else if (maxStakeValue === undefined) {
      handleChange(token.toString());
    }
  };

  return (
    <ul className={s.tab}>
      <li>
        <Typography type="h3" size="f20">
          Staking phase
        </Typography>
        <p>
          There is &nbsp; <strong>{currentLimit.text}</strong>&nbsp; to stake in this pool. You can stake in as many of
          the pools as you wish. If you require support please go to our official &nbsp;{' '}
          <strong>Telegram Support</strong>
        </p>
      </li>
      <li>
        <div className={s.row}>
          <div>
            <Typography type="span" size="f12" color="gray" uppercase className={s.subtitle}>
              Pool details
            </Typography>
            <Typography type="span" size="f16">
              Total $TOTO Staked
            </Typography>
          </div>
          <Typography type="span" size="f16" color="red">
            {totalStaked}
          </Typography>
        </div>
      </li>
      <li>
        <div className={s.row}>
          <div>
            <Typography type="span" size="f12" color="gray" uppercase className={s.subtitle}>
              Your stake
            </Typography>
            <Typography type="span" size="f16">
              $TOTO staked in this pool
            </Typography>
          </div>
          <Typography type="span" size="f16" color="red">
            {userStaked}
          </Typography>
        </div>

        <div className={s.row}>
          <Typography size="f16">Your % of this project pool</Typography>
          <Typography type="span" size="f16" color="red">
            {+totalStaked !== 0 && +userStaked !== 0 ? `${((+userStaked / +totalStaked) * 100).toFixed(2)}%` : 0}
          </Typography>
        </div>
      </li>
      <li>
        <p>
          How much do you want to <strong>Stake?</strong>
        </p>
        <Typography size="f12" color="red">
          Once staked you can not unstake.
        </Typography>
        <InputWrapper
          id="stake"
          positiveOnly
          isNumber
          size="lg"
          value={value}
          placeholder="0"
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e.target.value)}
          className={s.input}
          max={maxStakeValue}
          postfix={
            <Button size="sm" color="gray" onClick={handleSetMaxStake} className={s.maxBtn}>
              MAX
            </Button>
          }
        />
        <Button color="outline" isLoading={isLoading} onClick={onStake}>
          Confirm Stake
        </Button>
      </li>
    </ul>
  );
};
