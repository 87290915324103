import { useState, VFC } from 'react';

import cn from 'clsx';

import { CardBox, Switcher } from 'components';
import { projectPages, switcherItems } from './ProjectFormCard.helper';
import s from './styles.module.scss';

export interface ProjectFormCardProps {
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cardData: any;
}

export const ProjectFormCard: VFC<ProjectFormCardProps> = ({ className, cardData }) => {
  const [activeTab, setactiveTab] = useState(switcherItems[0]);
  return (
    <CardBox className={cn(s.projectFormCard, className)}>
      <Switcher option={switcherItems} active={activeTab} onChangeActive={setactiveTab} />
      {activeTab.value === 0 && projectPages.renderOverview(cardData)}
      {activeTab.value === 1 && projectPages.renderTokenomics(cardData)}
      {activeTab.value === 2 && projectPages.renderTeam(cardData)}
    </CardBox>
  );
};
