/* eslint-disable @typescript-eslint/no-explicit-any */
import { INetwork, IProvider, ISettings } from '@amfi/connect-wallet/dist/interface';

export enum ChainsEnum {
  'Binance-Smart-Chain' = 'Binance-Smart-Chain',
  Ethereum = 'Ethereum',
  Avalanche = 'Avalanche',
}

export enum Chains {
  bsc = 'Binance-Smart-Chain',
  eth = 'Ethereum',
  avax = 'Avalanche',
}

export const HexChainsEnum = {
  '0x1': 1,
  '0x3': 3,
  '0x4': 4,
  '0x5': 5,
  '0x2a': 42,
  '0x80': 128,
  '0x100': 256,
  69: 69,
  24: 24,
  '0x38': 56,
  '0x61': 97,
  '0x89': 137,
  '0x13881': 80001,
  '0xa869': 43113,
  '0xa86a': 43114,
  '0xa4ec': 42220,
  '0xaef3': 44787,
};

export const requiredChaiIds = ['0x61', '0x1', '0xa869']; // for eth mainnet only

export const ChainIds = {
  '0x61': Chains.bsc,
  '0x1': Chains.eth,
  '0xa869': Chains.avax,
};

export type IChainType = 'testnet' | 'mainnet';

export interface IConnectWallet {
  network: INetwork;
  provider: {
    [index: string]: IProvider;
  };
  settings: ISettings;
}
export interface IChainConfig {
  name: string;
  id: number;
  rpc: string;
  tx: {
    link: string;
  };
  nativeCurrency: {
    name: string;
    symbol: string;
    decimals: number;
  };
  blockExp: string;
}

export interface IContracts {
  decimals: number;
  names: string[];
  contracts: {
    [index: string]: {
      testnet?: {
        address?: {
          [key in Chains]: string;
        };
        abi: any[];
      };
      mainnet?: {
        address?: {
          [key in Chains]: string;
        };
        abi: any[];
      };
    };
  };
}
