import { CSSProperties, FC, ReactElement, useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { arrowBottom } from 'assets/img/section';
import { OutsideClick } from '../index';
import s from './styles.module.scss';

export interface IOption {
  img?: string;
  value: string | number;
  text?: string | ReactElement;
}

export interface SelectProps {
  className?: string;
  boxClassName?: string;
  style?: CSSProperties;
  defaultOption: IOption;
  options: IOption[];
  onSelect?: (value: IOption) => void;
  color?: 'gray' | 'dark' | 'white';
  isCurrency?: boolean;
  size?: 'mini' | 'big' | 'form';
  title?: ReactElement;
  subtitle?: ReactElement;
  error?: string;
  allowToChange?: boolean;
  arrow?: boolean;
}

export const Select: FC<SelectProps> = ({
  className,
  style,
  error,
  defaultOption,
  options,
  color = 'dark',
  onSelect,
  boxClassName,
  isCurrency,
  size = 'big',
  title,
  subtitle,
  arrow = false,
  allowToChange = true,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentOption, setCurrentOption] = useState(defaultOption);

  const handleOpen = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleOutsideClick = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleSelect = useCallback(
    (option: IOption) => {
      setIsOpen(false);
      if (allowToChange) {
        setCurrentOption(option);
      }
      if (onSelect) {
        onSelect(option);
      }
    },
    [allowToChange, onSelect],
  );

  useEffect(() => {
    setCurrentOption(defaultOption);
  }, [defaultOption]);

  return (
    <div className={s.wrapper}>
      {title && <div className={s.title}>{title}</div>}
      <OutsideClick onClick={handleOutsideClick} className={boxClassName}>
        <div
          className={clsx(
            s.select,
            s[color],
            s[size],
            { [s.active]: isOpen, [s.currency]: isCurrency },
            boxClassName,
          )}
        >
          <div
            role="button"
            tabIndex={0}
            onKeyDown={() => {}}
            onClick={handleOpen}
            className={clsx(s.currentOption, className, s[size], s[color])}
            style={style}
          >
            <div className={clsx(s.currentOptionWrapper, s[size])}>
              {currentOption.img && (
                <div
                  className={clsx(s.img, {
                    [s.img_currency]: isCurrency,
                  })}
                >
                  <img src={currentOption.img} alt="icon" />
                </div>
              )}
              {currentOption.text || ''}
            </div>
            {arrow && (
              <img
                src={arrowBottom}
                alt="arrow"
                className={clsx(s.arrow, { [s.active]: isOpen })}
              />
            )}
          </div>
          {isOpen && (
            <div className={clsx(s.optionWrapper, s[size])}>
              {options.map((option) => (
                <div
                  key={option.value}
                  role="button"
                  tabIndex={-1}
                  onKeyDown={handleOpen}
                  onClick={() => handleSelect(option)}
                  className={clsx(
                    s.option,
                    {
                      [s.optionActive]: option.value === currentOption.value,
                    },
                    s[color],
                  )}
                >
                  {option.img && (
                    <div
                      className={clsx(s.img, {
                        [s.imgCurrency]: isCurrency,
                      })}
                    >
                      <img src={option.img} alt="" />
                    </div>
                  )}
                  {option.text || option.value}
                </div>
              ))}
            </div>
          )}
        </div>
      </OutsideClick>
      {subtitle && <div className={s.subtitle}>{subtitle}</div>}
      {error && <div className={s.error}>{error}</div>}
    </div>
  );
};
