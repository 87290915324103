import { put, takeLatest, call } from 'redux-saga/effects';
import apiActions from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import { getPosts } from '../actions';
import actionTypes from '../actionTypes';
import { updatePostsState } from '../reducer';

export function* getPostsSaga({ type }: ReturnType<typeof getPosts>) {
  yield put(apiActions.request(type));
  try {
    const { data } = yield call(baseApi.getPosts);

    const sortedPosts = [];
    for (let i = data.length - 1; i >= 0; i -= 1) {
      sortedPosts.push(data[i]);
    }

    yield put(
      updatePostsState({
        posts: sortedPosts,
      }),
    );

    yield put(apiActions.success(type));
  } catch (err) {
    yield put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_POSTS, getPostsSaga);
}
