export const projectPhases = [
  {
    value: 1,
    text: 'Staking',
  },
  {
    value: 2,
    text: 'Funding',
  },
  {
    value: 3,
    text: 'Distributing',
  },
];
