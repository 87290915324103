import { FC, useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { UrlObject } from 'url';
import { Disclaimer, Footer, Header, NotificationModal } from 'containers';
import { useWalletConnectorContext } from 'services';
import { useShallowSelector, useSmoothTopScroll, useModal, useCheckNotAllowRoute } from 'hooks';
import { State, UserState, WalletProviders } from 'types';
import userSelector from 'store/user/selectors';
import { useDispatch } from 'react-redux';
import { getTotalFilterProjects } from 'store/projects/actions';
import { getTotoroBalance } from 'store/balances/actions';
import { getPosts } from 'store/posts/actions';
import s from './styles.module.scss';

export interface LayoutProps {
  route?: UrlObject | string;
}

export const Layout: FC<LayoutProps> = ({ children }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { connect, disconnect, initConnectToRequiredChain, walletService } = useWalletConnectorContext();

  const [mustDisclaimerOpend, , onCloseDisclaimer] = useModal(true);
  const { address, network } = useShallowSelector<State, UserState>(userSelector.getUser);

  const firstPathAtPathname = useMemo(() => pathname.split('/')[1], [pathname]);
  useSmoothTopScroll(firstPathAtPathname);
  useCheckNotAllowRoute(pathname);

  const handleConnectWallet = useCallback(
    async (provider = WalletProviders.metamask, newChain) => {
      connect(provider, newChain);
    },
    [connect],
  );

  const disconnectWallet = useCallback(() => {
    disconnect();
  }, [disconnect]);

  const isDisclaimerVisible = useMemo(() => {
    const isCookieAgree = document.cookie;
    if (!isCookieAgree && mustDisclaimerOpend) {
      return true;
    }
    return false;
  }, [mustDisclaimerOpend]);

  useEffect(() => {
    dispatch(getTotalFilterProjects());

    dispatch(getPosts());
  }, [dispatch]);

  useEffect(() => {
    if (address.length && network.length) {
      dispatch(
        getTotoroBalance({
          web3Provider: walletService.Web3(),
        }),
      );
    }
  }, [address.length, dispatch, network.length, walletService]);

  return (
    <>
      <Disclaimer isOpen={isDisclaimerVisible} onClose={onCloseDisclaimer} />
      <NotificationModal
        onConnectWallet={handleConnectWallet}
        initConnectToRequiredChain={initConnectToRequiredChain}
      />
      <Header onConnectWallet={handleConnectWallet} disconnect={disconnectWallet} />

      <main className={s.mainContainer}>{children}</main>
      <Footer />
    </>
  );
};
