import { logo } from 'assets/img/section';
import { Typography } from 'components';
import { useWindowState } from 'hooks';
import { VFC } from 'react';
import { Link } from 'react-router-dom';
import s from '../styles.module.scss';

export const StaticPart: VFC = () => {
  const { width } = useWindowState();

  return (
    <div className={s.rowGridStatic}>
      {+width < 1024 && (
        <div className={s.item}>
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </div>
      )}

      <Link to="/announcements" className={s.item}>
        <Typography type="span" color="gray" size="f16">
          Announcements
        </Typography>
      </Link>

      <div className={s.item}>
        <a href="https://www.kucoin.com/" target="_blank" rel="noreferrer">
          <Typography type="span" color="gray" size="f16" className={s.itemLink}>
            Click here to <strong>Buy TOTO</strong>
          </Typography>
        </a>
      </div>
    </div>
  );
};
