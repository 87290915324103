import { put, takeLatest, call, select } from 'redux-saga/effects';
import apiActions from 'store/api/actions';
import { messageGenerator } from 'utils';
import { baseApi } from 'store/api/apiRequestBuilder';
import userSelector from 'store/user/selectors';
import { Modals } from 'types';
import { setActiveModal } from 'store/modals/reducer';
import { toast } from 'react-toastify';
import { notifyText } from 'config/constants';
import { updateUserState } from '../reducer';
import { signIn } from '../actions';
import actionTypes from '../actionTypes';

export function* signInSaga({
  type,
  payload: { email, password, web3Provider },
}: ReturnType<typeof signIn>) {
  yield put(apiActions.request(type));
  try {
    const address = yield select(userSelector.getProp('address'));
    const metamaskMessage = messageGenerator(26);
    const signedMessage = yield call(web3Provider.eth.personal.sign, metamaskMessage, address, '');

    const { data } = yield call(baseApi.metamaskSignIn, {
      address,
      email,
      password,
      message: metamaskMessage,
      signature: signedMessage,
    });

    if (data.authToken) {
      yield put(
        updateUserState({
          account: {
            email,
          },
          key: data.authToken,
          isAdmin: data.isAdmin,
        }),
      );
      yield put(
        setActiveModal({
          activeModal: Modals.init,
          txHash: '',
          open: false,
        }),
      );

      toast.success(notifyText.signIn.successful);
    } else if (data.Error.details) {
      toast.error(data.Error.details);
    }

    yield put(apiActions.success(type));
  } catch (err) {
    yield put(apiActions.error(type, err));
    toast.error(notifyText.signIn.error);
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.SIGN_IN, signInSaga);
}
