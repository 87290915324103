import { call, put, takeLatest } from 'redux-saga/effects';
import apiActions from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import { getTotalFilterProjects } from '../actions';
import actionTypes from '../actionTypes';
import { updateProjectsState } from '../reducer';

export function* getTotalFilterProjectsSaga({ type }: ReturnType<typeof getTotalFilterProjects>) {
  yield put(apiActions.request(type));

  const allUrl = 'sort=all&stage=all&page=1&size=2';
  const stakingUrl = 'sort=all&stage=staking&page=1&size=2';
  const fundingUrl = 'sort=all&stage=funding&page=1&size=4';
  const distributingUrl = 'sort=all&stage=distributing&page=1&size=4';
  const completedUrl = 'sort=all&stage=completed&page=1&size=4';

  try {
    const allProjects = yield call(baseApi.getAllProjects, allUrl);
    const stakingProjects = yield call(baseApi.getAllProjects, stakingUrl);
    const fundingProjects = yield call(baseApi.getAllProjects, fundingUrl);
    const distributingProjects = yield call(baseApi.getAllProjects, distributingUrl);
    const completedProjects = yield call(baseApi.getAllProjects, completedUrl);

    yield put(
      updateProjectsState({
        totalFilterProjects: [
          {
            title: 'All Projects',
            total: allProjects.data.total,
            projects: allProjects.data.items,
            mustView: false,
          },
          {
            title: 'Staking Projects',
            total: stakingProjects.data.total,
            projects: stakingProjects.data.items,
            mustView: true,
          },
          {
            title: 'Funding Projects',
            total: fundingProjects.data.total,
            projects: fundingProjects.data.items,
            mustView: true,
          },
          {
            title: 'Distributing Projects',
            total: distributingProjects.data.total,
            projects: distributingProjects.data.items,
            mustView: true,
          },
          {
            title: 'Completed Projects',
            total: completedProjects.data.total,
            projects: completedProjects.data.items,
            mustView: true,
          },
        ],
      }),
    );

    yield put(apiActions.success(type));
  } catch (err) {
    console.log(err);
    yield put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_TOTAL_FILTER_PROJECTS, getTotalFilterProjectsSaga);
}
