import { call, put, select, takeLatest } from 'redux-saga/effects';
import apiActions from 'store/api/actions';
import userSelector from 'store/user/selectors';
import projectSelector from 'store/projects/selectors';
import { contractsConfig, ContractsNames } from 'config';
import { getTokenAmountDisplay } from 'utils';
import { updateBalancesState } from 'store/balances/reducer';
import actionTypes from '../actionTypes';
import { getBalancesInfo } from '../actions';

export function* getBalanceInfoSaga({
  type,
  payload: { web3Provider, activeLimit },
}: ReturnType<typeof getBalancesInfo>) {
  yield put(apiActions.request(type));

  const myAddress = yield select(userSelector.getProp('address'));
  const network = yield select(userSelector.getProp('network'));
  const chainType = yield select(userSelector.getProp('chainType'));

  const { pid, stage } = yield select(projectSelector.getProject('currentProject'));
  const activePidValue = pid * 3 + activeLimit;

  const { abi: tokenAbi, address: tokenAddress } = contractsConfig.contracts[ContractsNames.totoro][chainType];
  const { abi: totopadAbi, address: totopadAddress } = contractsConfig.contracts[ContractsNames.totopad][chainType];

  try {
    const tokenContract = yield new web3Provider.eth.Contract(tokenAbi, tokenAddress[network]);
    const totopadContract = yield new web3Provider.eth.Contract(totopadAbi, totopadAddress[network]);

    if (myAddress) {
      const decimals = yield call(tokenContract.methods.decimals().call);

      const { totalStaked } = yield call(totopadContract.methods.poolInfo(activePidValue).call);
      const { amount, fundingAmount } = yield call(totopadContract.methods.userInfo(activePidValue, myAddress).call);

      yield put(
        updateBalancesState({
          totalStaked: getTokenAmountDisplay(totalStaked, decimals),
          userStaked: getTokenAmountDisplay(amount, decimals),
        }),
      );

      if (stage === 'funding') {
        const pledgeFundingAmount = yield call(
          totopadContract.methods.getPledgeFundingAmount(activePidValue, myAddress).call,
        );

        yield put(
          updateBalancesState({
            fundingBalance: pledgeFundingAmount,
            haveFundation: +pledgeFundingAmount - +fundingAmount === 0,
          }),
        );
      }

      if (stage === 'distributing' || stage === 'completed') {
        const { totalRaised, softCap } = yield call(totopadContract.methods.projectInfo(pid).call);
        const rewardAmount = yield call(totopadContract.methods.pendingRewards(activePidValue, myAddress).call);
        const projectInfo = yield call(totopadContract.methods.poolInfo(pid).call);
        // const pledgeFundingAmount = yield call(
        //   totopadContract.methods.getPledgeFundingAmount(activePidValue, myAddress).call,
        // );
        const isReqcruited = +totalRaised >= +softCap;
        const haveRewards = +rewardAmount !== 0;
        // const isSoftCapRecruited = isReqcruited && haveRewards;

        yield put(
          updateBalancesState({
            softCapRecruited: isReqcruited,
            haveRewards,
            fundingBalance: fundingAmount,
            haveFundation: (+amount > 0 && !isReqcruited) || (isReqcruited && +fundingAmount === 0 && +amount > 0),
            rewardInfo: {
              totalRaised,
              softCap,
              rewardAmount: projectInfo.rewardAmount,
            },
          }),
        );
      }
    }
    yield put(apiActions.success(type));
  } catch (err) {
    console.log(err);
    yield put(
      updateBalancesState({
        fundingBalance: '0',
        haveFundation: true,
      }),
    );
    yield put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_BALANCES_INFO, getBalanceInfoSaga);
}
