import { useCallback, VFC } from 'react';
import cn from 'clsx';
import { Button, CardBox, Typography } from 'components';
import { ChainsEnum, Modals, WalletProviders } from 'types';
import { useWalletConnectorContext } from 'services';
import { useDispatch } from 'react-redux';
import { setActiveModal } from 'store/modals/reducer';
import s from './styles.module.scss';

export interface ConnectFormCardProps {
  className?: string;
  projectNetwork: string;
  address: string;
  keySignature: string;
  network: string;
  provider: string;
}

export const ConnectFormCard: VFC<ConnectFormCardProps> = ({
  className,
  projectNetwork,
  address,
  keySignature,
  network,
  provider,
}) => {
  const { connect } = useWalletConnectorContext();
  const dispatch = useDispatch();

  const handleOpenModal = useCallback(
    (type: Modals) => {
      dispatch(
        setActiveModal({
          activeModal: type,
          open: true,
        }),
      );
    },
    [dispatch],
  );

  const handleConnectWallet = useCallback(() => {
    if (!address.length) {
      handleOpenModal(Modals.ConnectWallet);
    } else {
      connect(provider as WalletProviders, ChainsEnum[projectNetwork]);
    }
  }, [address.length, connect, handleOpenModal, projectNetwork, provider]);

  return (
    <CardBox className={cn(s.connectFormCard, className)}>
      <Typography size="f24">Staking requirements</Typography>
      <Typography size="f14" color="gray">
        To get involved in project staking please make sure you have complete the following requirements.
      </Typography>

      <div className={s.controls}>
        {(!address || network !== projectNetwork) && (
          <Button color="outline" onClick={handleConnectWallet}>
            {!address.length ? 'Connect wallet' : 'Switch Network'}
          </Button>
        )}
        {!keySignature && !!address.length && (
          <Button color="outline" onClick={() => handleOpenModal(Modals.SignIn)}>
            Sign In
          </Button>
        )}
      </div>
    </CardBox>
  );
};
