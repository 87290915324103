import { logo } from 'assets/img/section';
import { Typography } from 'components';
import { PoweredCard } from 'containers/Cards/PoweredCard';
import { VFC } from 'react';
import { contactLinks, footerText, socialLinks } from './index';

import s from './styles.module.scss';

export const Footer: VFC = () => {
  return (
    <footer className={s.wrapper}>
      <img src={logo} alt="logo" className={s.logo} />

      <div className={s.container}>
        <Typography type="div" color="gray" size="f18" className={s.containerText}>
          {footerText}
        </Typography>

        <div className={s.containerBottom}>
          <ul>
            {contactLinks.map(({ title, link }, index) => (
              <li key={title}>
                <a href={index === 0 ? `mailto: ${link}` : link} target="_blank" rel="noreferrer">
                  <Typography type="span" color="gray" uppercase>
                    {title}
                  </Typography>
                </a>
              </li>
            ))}
          </ul>
          <div className={s.socials}>
            <ul>
              {socialLinks.map(({ img, link }) => (
                <li key={img}>
                  <a href={link} target="_blank" rel="noreferrer">
                    <img src={img} alt={img} />
                  </a>
                </li>
              ))}
            </ul>
            <PoweredCard />
          </div>
        </div>
      </div>
    </footer>
  );
};
