import { PropsWithChildren, VFC } from 'react';

import cn from 'clsx';

import s from './styles.module.scss';

export interface TypographyProps {
  type?: 'div' | 'p' | 'span' | 'h1' | 'h2' | 'h3';
  size?: 'f14' | 'f12' | 'f10' | 'f16' | 'f18' | 'f20' | 'f22' | 'f24' | 'f28' | 'f30';
  uppercase?: boolean;
  color?: 'white' | 'gray' | 'gray-dark' | 'red' | 'blue' | 'green' | 'orange' | 'orange-dark';
  border?: boolean;
  className?: string;
}

export const Typography: VFC<PropsWithChildren<TypographyProps>> = ({
  className,
  type = 'div',
  size = (type === 'h1' && 'f28') || (type === 'h2' && 'f20') || (type === 'h3' && 'f24') || 'f14',
  uppercase = false,
  color = 'white',
  border = false,
  children,
}) => {
  const classes = [
    s[type],
    s[color],
    s[size],
    { [s.uppercase]: uppercase },
    { [s.border]: border },
    className,
  ];

  let htmlText;
  switch (type) {
    case 'span':
      htmlText = <span className={cn(...classes)}>{children}</span>;
      break;
    case 'p':
      htmlText = <p className={cn(...classes)}>{children}</p>;
      break;
    case 'div':
      htmlText = <div className={cn(...classes)}>{children}</div>;
      break;
    case 'h1':
      htmlText = <h1 className={cn(...classes)}>{children}</h1>;
      break;
    case 'h2':
      htmlText = <h2 className={cn(...classes)}>{children}</h2>;
      break;
    case 'h3':
      htmlText = <h3 className={cn(...classes)}>{children}</h3>;
      break;
    default:
      htmlText = <div className={cn(...classes)}>{children}</div>;
  }
  return <>{htmlText}</>;
};
