import { put, takeLatest, call } from 'redux-saga/effects';
import apiActions from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import { Modals } from 'types';
import { setActiveModal } from 'store/modals/reducer';
import { toast } from 'react-toastify';
import { notifyText } from 'config/constants';
import { resetPassword } from '../actions';
import actionTypes from '../actionTypes';

export function* resetPasswordSaga({ type, payload: { email } }: ReturnType<typeof resetPassword>) {
  yield put(apiActions.request(type));
  try {
    yield call(baseApi.resetPassword, {
      email,
    });
    yield put(apiActions.success(type));
    yield put(
      setActiveModal({
        activeModal: Modals.init,
        txHash: '',
        open: false,
      }),
    );
    toast.info(notifyText.resetPassword.info);
  } catch (err) {
    console.log(err);
    yield put(apiActions.error(type, err));
    toast.error(notifyText.resetPassword.error);
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.RESET_PASSWORD, resetPasswordSaga);
}
