import { contractsConfig, ContractsNames } from 'config';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import apiActions from 'store/api/actions';
import userSelector from 'store/user/selectors';
import { getTokenAmountDisplay } from 'utils';
import { updateBalancesState } from 'store/balances/reducer';
import actionTypes from '../actionTypes';
import { getStakedSoFar } from '../actions';

export function* getStakedSoFarSaga({
  type,
  payload: { web3Provider, pid },
}: ReturnType<typeof getStakedSoFar>) {
  yield put(apiActions.request(type));

  const chainType = yield select(userSelector.getProp('chainType'));
  const network = yield select(userSelector.getProp('network'));

  const { abi: tokenAbi, address: tokenAddress } =
    contractsConfig.contracts[ContractsNames.totoro][chainType];
  const { abi: totopadAbi, address: totopadAddress } =
    contractsConfig.contracts[ContractsNames.totopad][chainType];

  try {
    const tokenContract = yield new web3Provider.eth.Contract(tokenAbi, tokenAddress[network]);
    const totopadContract = yield new web3Provider.eth.Contract(
      totopadAbi,
      totopadAddress[network],
    );
    const decimals = yield call(tokenContract.methods.decimals().call);

    let stakedSoFar = 0;
    for (let i = 0; i < 3; i += 1) {
      const value = yield call(totopadContract.methods.poolInfo(pid * 3 + i).call);
      stakedSoFar += +value.totalStaked;
    }

    yield put(
      updateBalancesState({
        stakedSoFar: getTokenAmountDisplay(stakedSoFar, decimals),
      }),
    );

    yield put(apiActions.success(type));
  } catch (err) {
    console.log(err);
    yield put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_STAKED_SO_FAR, getStakedSoFarSaga);
}
