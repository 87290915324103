import { VFC } from 'react';
import cn from 'clsx';
import { Typography, CardBox } from 'components';
import { dateFormatter } from 'utils';
import s from './styles.module.scss';

export interface AnnounceCardProps {
  title: string;
  className?: string;
  date: string;
}

export const AnnounceCard: VFC<AnnounceCardProps> = ({ className, title, date }) => {
  return (
    <CardBox className={cn(s.container, className)}>
      <Typography type="span" color="white" size="f18" className={s.text}>
        {title}
      </Typography>
      <Typography type="span" color="gray" size="f14" uppercase>
        {dateFormatter(date, 'll')}
      </Typography>
    </CardBox>
  );
};
