import { VFC } from 'react';
import clsx from 'clsx';
import { CardBox, SkeletonLoader } from 'components';
import s from './styles.module.scss';

export interface SkeletonPostProps {
  className?: string;
}

export const SkeletonPost: VFC<SkeletonPostProps> = ({ className }) => {
  return (
    <CardBox className={clsx(s.card, className)}>
      <SkeletonLoader width="150px" />
      <SkeletonLoader width="200px" />
      <SkeletonLoader width="250px" />
    </CardBox>
  );
};
