import {
  avatar,
  discordGray,
  githubGray,
  linkedinIcon,
  mediumGray,
  telegramGray,
  twitterGray,
} from 'assets/img/section';
import { Button, Typography } from 'components';
import { networkIcons } from 'config/constants';
import { ChainsEnum } from 'types';
import { dateFormatter } from 'utils';
import s from './styles.module.scss';

export const switcherItems = [
  {
    value: 0,
    text: 'Project Overview',
  },
  {
    value: 1,
    text: 'Tokenomics',
  },
  {
    value: 2,
    text: 'Team & Roadmap',
  },
];

export const projectPages = {
  renderOverview: (cardData) => {
    const { twitter, telegram, discord, medium, github } = cardData;
    return (
      <>
        {typeof cardData.banner === 'string' && <img src={cardData.banner} alt="poster" className={s.projectPoster} />}
        {typeof cardData.banner !== 'string' && (
          <img
            src={cardData.banner ? URL.createObjectURL(cardData.banner) : ''}
            alt="poster"
            className={s.projectPoster}
          />
        )}

        <div className={s.raisBlock}>
          <div>
            <Typography color="gray" size="f12" uppercase>
              Raise Network :
            </Typography>
            <Typography className={s.network}>
              <img src={networkIcons[ChainsEnum[cardData.networkName]]} alt="icon" />
              {cardData.networkName}
            </Typography>
          </div>
          <div>
            <Typography color="gray" size="f12" uppercase>
              Target Raise :
            </Typography>
            <Typography>{cardData.targetRace}</Typography>
          </div>
          <div>
            <Typography color="gray" size="f12" uppercase>
              Soft Cap :
            </Typography>
            <Typography>{cardData.softCap}</Typography>
          </div>
        </div>
        <div className={s.summary}>
          <Typography type="span" size="f20">
            Summary
          </Typography>
          <Typography type="span" color="gray" size="f16">
            {cardData.summary}
          </Typography>
        </div>
        <div className={s.community}>
          {twitter && telegram && discord && medium && github && (
            <>
              <Typography type="span" size="f20">
                Community and Social Channels
              </Typography>
              <div className={s.links}>
                {twitter && (
                  <a href={twitter} target="_blank" rel="noreferrer">
                    <img src={twitterGray} alt="twitter" />
                  </a>
                )}

                {telegram && (
                  <a href={telegram} target="_blank" rel="noreferrer">
                    <img src={telegramGray} alt="twitter" />
                  </a>
                )}
                {discord && (
                  <a href={discord} target="_blank" rel="noreferrer">
                    <img src={discordGray} alt="discord" />
                  </a>
                )}
                {medium && (
                  <a href={medium} target="_blank" rel="noreferrer">
                    <img src={mediumGray} alt="medium" />
                  </a>
                )}
                {github && (
                  <a href={github} target="_blank" rel="noreferrer">
                    <img src={githubGray} alt="github" />
                  </a>
                )}
              </div>
            </>
          )}

          {cardData.whitepaper && (
            <a href={cardData.whitepaper} target="_blank" rel="noreferrer">
              <Button color="filled" className={s.whitepaperBtn}>
                View Whitepaper
              </Button>
            </a>
          )}
        </div>
      </>
    );
  },
  renderTokenomics: (cardData) => (
    <>
      <Typography type="span" size="f24" className={s.title}>
        Tokenomics
      </Typography>
      <ul className={s.tokenomicsList}>
        <li>
          <Typography type="span" color="gray" size="f16">
            Allocation Size:
          </Typography>
          {cardData.rewardsTokenAmount}
        </li>
        <li>
          <Typography type="span" color="gray" size="f16">
            Price on IDO:
          </Typography>
          {cardData.tokenPrice}
        </li>
        <li>
          <Typography type="span" color="gray" size="f16">
            Ticker:
          </Typography>
          {cardData.ticker}
        </li>
        <li>
          <Typography type="span" color="gray" size="f16">
            Network:
          </Typography>
          <img src={networkIcons[ChainsEnum[cardData.networkName]]} alt="icon" />
          {cardData.networkName}
        </li>
        <li>
          <Typography color="gray" size="f16">
            - 20% after 30 days (with a bonus 30% (reducing overall token price to 7.5 cents) after 60 days if no tokens
            have been claimed from the contract)
          </Typography>
        </li>
        <li>- 30% after 3 months</li>
        <li>- 25% after 5 months</li>
        <li>- 25% after 12 months</li>
        <li>
          <Typography color="gray" size="f16">
            Market Cap on Listing:&nbsp;
          </Typography>
          {cardData.marketCapOnLaunch}
        </li>
      </ul>
      <Typography type="span" size="f24" className={s.title}>
        Project Schedule
      </Typography>
      <ul className={s.tokenomicsList}>
        <li>
          <Typography type="span" color="gray" size="f16">
            Staking Start Time
          </Typography>
          {dateFormatter(cardData.allocStartTime)}
        </li>
        <li>
          <Typography type="span" color="gray" size="f16">
            Staking End Time / Funding Start Time
          </Typography>
          {dateFormatter(cardData.stakingEndTime)}
        </li>
        <li>
          <Typography type="span" color="gray" size="f16">
            Funding End Time
          </Typography>
          <Typography type="span" color="gray" size="f16">
            / Rewards Distribution Start Time
          </Typography>
          {dateFormatter(cardData.fundingEndTime)}
        </li>
        <li>
          <Typography type="span" color="gray" size="f16">
            Rewards Distribution End Time
          </Typography>
          {dateFormatter(cardData.rewardStopTime)}
        </li>
      </ul>
      <Typography type="span" size="f14" className={s.bottomNote}>
        * Note: Changes (which will be communicated to the TotoPad community) may occur to vesting schedule,
        distribution, and token claims, which are outside of TotoPads control.
      </Typography>
    </>
  ),
  renderTeam: (cardData) => (
    <div className={s.team}>
      {cardData.team.map(({ photo, name, position, linkedin, description }, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className={s.teamItem} key={name + index}>
          <div className={s.teamItemTop}>
            {photo ? (
              <>
                {typeof photo !== 'string' && (
                  <img src={URL.createObjectURL(photo)} alt="avatar" className={s.teamItemAvatar} />
                )}
                {typeof photo === 'string' && <img src={photo} alt="avatar" className={s.teamItemAvatar} />}
              </>
            ) : (
              <img src={avatar} alt="avatar" />
            )}
            <Typography type="span" size="f20">
              {name}
            </Typography>
            <Typography type="span" size="f16" color="gray">
              {position}
            </Typography>
          </div>
          <Typography type="p" color="gray" size="f16">
            {description}
          </Typography>
          {linkedin && (
            <a href={linkedin} target="_blank" rel="noreferrer">
              <img src={linkedinIcon} alt="icon" />
            </a>
          )}
        </div>
      ))}
    </div>
  ),
};
