/* eslint-disable @typescript-eslint/no-explicit-any */
import { VFC } from 'react';
import { Button, Typography } from 'components';
import { getTokenAmountDisplay } from 'utils';
import { Chains } from 'types';
import { ITabProps } from '../PoolFromCard.types';
import s from '../styles.module.scss';

export const FundingTab: VFC<ITabProps> = ({ onFund, isLoading, currentProject, balance, network, currentLimit }) => {
  const { totalStaked, userStaked, fundingBalance, haveFundation } = balance;
  return (
    <ul className={s.tab}>
      <li>
        <Typography type="h3" size="f20">
          Funding phase
        </Typography>
        <Typography type="p">
          Project staking is now closed. You have successfully staked into the &nbsp;{' '}
          <strong>{currentLimit.text}</strong>&nbsp; pool and your final allocation is listed below. If you require
          support please go to our official <strong>Telegram Support</strong>
        </Typography>
      </li>
      <li>
        <div className={s.row}>
          <div>
            <Typography type="span" size="f12" color="gray" uppercase className={s.subtitle}>
              Pool details
            </Typography>
            <Typography type="span" size="f16">
              Total $TOTO Staked
            </Typography>
          </div>
          <Typography type="span" size="f16" color="red">
            {totalStaked}
          </Typography>
        </div>
      </li>
      <li>
        <div className={s.row}>
          <div>
            <Typography type="span" size="f12" color="gray" uppercase className={s.subtitle}>
              Your stake
            </Typography>
            <Typography type="span" size="f16">
              $TOTO staked in this pool
            </Typography>
          </div>
          <Typography type="span" size="f16" color="red">
            {userStaked}
          </Typography>
        </div>

        <div className={s.row}>
          <Typography size="f16">Your % of this project pool</Typography>
          <Typography type="span" size="f16" color="red">
            {+totalStaked !== 0 && +userStaked !== 0 ? `${((+userStaked / +totalStaked) * 100).toFixed(2)}%` : 0}
          </Typography>
        </div>
      </li>
      <li>
        {!haveFundation && (
          <>
            <Typography size="f14">What do next ?</Typography>
            <Typography type="p">
              To secure your allocation and to claim back your staked TOTO please send the required pledge amount
            </Typography>
            <Typography size="f18" className={s.fundingSubtitle}>
              Your funding amount
            </Typography>
            <Typography size="f18" color="red">
              <div className={s.circle} />
              {getTokenAmountDisplay(fundingBalance, network === Chains.avax ? 18 : 6)}&nbsp;
              {currentProject.fundingToken}
            </Typography>
            <Button color="outline-green" isLoading={isLoading} onClick={onFund} className={s.fundBtn}>
              Fund with {currentProject.fundingToken}
            </Button>
            <Typography type="p">
              You have approximately <strong>&nbsp;23 hours 31 mins 47 secs</strong> remaining to complete this action
              or you will lose your allocation. If you choose not to fund this project, you can still claim your TOTO
              back after the timer above runs out.
            </Typography>
          </>
        )}
        {haveFundation && (
          <Typography size="f18">
            Congratulations, you have successfully joined the foundation of the project
          </Typography>
        )}
      </li>
    </ul>
  );
};
