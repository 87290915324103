import { routes } from 'appConstants/routes';
import { FC, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

const Home = lazy(() => import('pages/Home').then((module) => ({ default: module.Home })));
const SignUp = lazy(() => import('pages/SignUp').then((module) => ({ default: module.SignUp })));
const Announcements = lazy(() => import('pages/Announcements').then((module) => ({ default: module.Announcements })));
const Details = lazy(() => import('pages/Account/Details').then((module) => ({ default: module.Details })));
const Projects = lazy(() => import('pages/Account/Projects').then((module) => ({ default: module.Projects })));
const FiltersPage = lazy(() => import('pages/FiltersPage').then((module) => ({ default: module.FiltersPage })));
const CreateProject = lazy(() => import('pages/CreateProject').then((module) => ({ default: module.CreateProject })));
const Project = lazy(() => import('pages/Project').then((module) => ({ default: module.Project })));
const Admin = lazy(() => import('pages/Admin').then((module) => ({ default: module.Admin })));

export const RouteManager: FC = () => {
  return (
    <Routes>
      <Route path="*" element={<Navigate to="/" />} />
      <Route path={routes.root} element={<Home />} />
      <Route path={routes.projects.all.root} element={<FiltersPage />} />
      <Route path={routes.signup.root} element={<SignUp />} />
      <Route path={routes.announcements.root} element={<Announcements />} />
      <Route path={routes.account.details.root} element={<Details />} />
      <Route path={routes.account.projects.root} element={<Projects />} />
      <Route path={routes.projects.create.root} element={<CreateProject />} />
      <Route path={routes.projects.current.root} element={<Project />} />
      <Route path={routes.admin.root} element={<Admin />} />
    </Routes>
  );
};
