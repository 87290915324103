import {
  //  discord,
  // medium,
  telegram,
  twitter,
} from 'assets/img/section';

export const contactLinks = [
  {
    title: 'contact us',
    link: 'support@totopad.io',
  },
  {
    title: 'terms & conditions',
    link: '/',
  },
  {
    title: 'privacy policy',
    link: '/',
  },
  {
    title: 'faq',
    link: '/',
  },
];

export const socialLinks = [
  {
    img: twitter,
    link: 'https://twitter.com/totopad',
  },
  // {
  //   img: medium,
  //   link: '/',
  // },
  // {
  //   img: discord,
  //   link: '/',
  // },
  {
    img: telegram,
    link: 'https://t.me/totopad',
  },
];

export const footerText =
  'Launching hand-picked high-quality projects on the Blockchain.TotoPad is a decentralized multi-chain fundraising platform enabling projects to raise capital and promise safety to early stage investors. Stake TOTO tokens to get priority-access to promising projects.';
