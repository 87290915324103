import { fork } from 'redux-saga/effects';

import getStakedSoFar from './getStakedSoFarSaga';
import getBalanceInfo from './getBalanceInfoSaga';
import getTotoroBalance from './getTotoroBalanceSaga';

export default function* balancesSagas() {
  yield fork(getStakedSoFar);
  yield fork(getBalanceInfo);
  yield fork(getTotoroBalance);
}
