import { call, put, select, takeLatest } from 'redux-saga/effects';
import apiActions from 'store/api/actions';
import { baseApi } from 'store/api/apiRequestBuilder';
import userSelector from 'store/user/selectors';
import { getUserProjects } from '../actions';
import actionTypes from '../actionTypes';
import { updateProjectsState } from '../reducer';

export function* getUserProjectsSaga({ type }: ReturnType<typeof getUserProjects>) {
  yield put(apiActions.request(type));

  const myAddress = yield select(userSelector.getProp('address'));

  try {
    const { data } = yield call(baseApi.getUserProject, myAddress);

    if (data.length) {
      yield put(updateProjectsState({ userProjects: data }));
    } else {
      yield put(updateProjectsState({ userProjects: [] }));
    }

    yield put(apiActions.success(type));
  } catch (err) {
    console.log(err);
    yield put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_USER_PROJECTS, getUserProjectsSaga);
}
