import { createAction } from '@reduxjs/toolkit';

import { LoginReq, ResetPasswordReq, UpdateUserInfoReq, ApproveReq } from 'types/requests';

import actionTypes from './actionTypes';

export const signUp = createAction<LoginReq>(actionTypes.SIGN_UP);
export const signIn = createAction<LoginReq>(actionTypes.SIGN_IN);
export const resetPassword = createAction<ResetPasswordReq>(actionTypes.RESET_PASSWORD);
export const updateUserInfo = createAction<UpdateUserInfoReq>(actionTypes.UPDATE_USER_INFO);
export const getUserInfo = createAction(actionTypes.GET_USER_INFO);
export const approve = createAction<ApproveReq>(actionTypes.APPROVE);
