import { avax, bsc, dots, eth } from 'assets/img/section';
import { CurrentNetwork, CurrentNetworkProps, Typography } from 'components';
import { Chains } from 'types';
import s from '../styles.module.scss';

const defaultSelectOptions = [
  {
    value: 1,
    text: (
      <Typography type="span" color="gray" size="f16">
        My Details
      </Typography>
    ),
  },
  {
    value: 2,
    text: (
      <Typography type="span" color="gray" size="f16">
        Create Project
      </Typography>
    ),
  },
  {
    value: 3,
    text: (
      <Typography type="span" color="red" size="f16">
        Disconnect wallet
      </Typography>
    ),
  },
  {
    value: 4,
    text: (
      <Typography type="span" color="red" size="f16">
        Sign Out
      </Typography>
    ),
  },
];

const adminSelectOption = {
  value: 5,
  text: (
    <Typography type="span" color="gray" size="f16">
      Admin Page
    </Typography>
  ),
};

const balanceSelectOption = (balance: number) => ({
  value: 6,
  text: (
    <div className={s.balance}>
      <Typography type="span" color="white" size="f12" uppercase>
        Balance:
      </Typography>
      <Typography color="red" size="f18" className={s.balanceText}>
        <Typography type="span" color="red" size="f18">
          {balance}
        </Typography>
        <Typography type="span" color="red" size="f18">
          TOTO
        </Typography>
      </Typography>
    </div>
  ),
});

const networkSelectOption = (props: CurrentNetworkProps) => ({
  value: 7,
  text: (
    <CurrentNetwork mobile onOpen={props.onOpen} network={props.network} currIcon={props.currIcon} className={s.item} />
  ),
});

const mobileLoginSelectOptions = [
  {
    value: 8,
    text: (
      <Typography type="span" color="gray" size="f16">
        Create account
      </Typography>
    ),
  },
  {
    value: 9,
    text: (
      <Typography type="span" color="gray" size="f16">
        Sign In
      </Typography>
    ),
  },
];
const announcementsSelectOption = () => ({
  value: 10,
  text: (
    <Typography type="span" color="gray" size="f16">
      Announcements
    </Typography>
  ),
});

export const selectOptionItems = {
  defaultOption: {
    img: dots,
    value: 0,
  },
  desktopOptions: (isAdmin: boolean) => (isAdmin ? [...defaultSelectOptions, adminSelectOption] : defaultSelectOptions),
  mobileOptions: (isSignIn: boolean, balance: number, props: CurrentNetworkProps) =>
    !isSignIn
      ? [
          networkSelectOption(props),
          announcementsSelectOption(),
          ...mobileLoginSelectOptions,
          balanceSelectOption(balance),
        ]
      : [
          networkSelectOption(props),
          announcementsSelectOption(),
          ...defaultSelectOptions,
          balanceSelectOption(balance),
        ],
};

export const networkitems = {
  title: 'Current Network',
  items: [
    {
      id: 1,
      title: Chains.bsc,
      icon: bsc,
      type: 'bsc',
    },
    {
      id: 2,
      title: Chains.eth,
      icon: eth,
      type: 'eth',
    },
    {
      id: 3,
      title: Chains.avax,
      icon: avax,
      type: 'avax',
    },
  ],
};
