/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, useCallback, VFC } from 'react';

import cn from 'clsx';

import { Typography } from 'components/Typography';
import s from './styles.module.scss';

export interface TextAreaProps {
  value?: any;
  onChange: (value: any) => void;
  onBlur?: (e: any) => void;
  rows?: number;
  placeholder?: string;
  className?: string;
  wrapperClassName?: string;
  max?: number;
  title?: string;
  id?: string;
}

export const TextArea: VFC<TextAreaProps> = ({
  className,
  wrapperClassName,
  onChange,
  value,
  max,
  title,
  id,
  ...props
}) => {
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      if (max && e.target.value.length < max) {
        return;
      }
      onChange(e);
    },
    [max, onChange],
  );

  return (
    <div className={cn(s.wrapper, wrapperClassName)}>
      {title && (
        <Typography type="span" size="f12" uppercase>
          {title}
        </Typography>
      )}
      <textarea
        {...props}
        id={id}
        className={cn(s.textArea, className)}
        onChange={handleChange}
        value={value}
      />
      {max && <div className={s.textareaCounter}>{`${value.length || 0}/${max}`}</div>}
    </div>
  );
};
