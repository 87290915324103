import { VFC } from 'react';

import clsx from 'clsx';

import { StatusField, Typography } from 'components';
import { advertText } from './index';
import { distrItem, raiseItem, targetItem } from './AdvertisingCard.helper';
import s from './styles.module.scss';

export interface AdvertisingCardProps {
  className?: string;
}

export const AdvertisingCard: VFC<AdvertisingCardProps> = ({ className }) => (
  <div className={clsx(s.wrapper, className)}>
    <div className={s.baner}>
      <StatusField stage="staking" />
    </div>

    <div className={s.container}>
      <div className={s.containerText}>
        <Typography type="h2">5ire</Typography>
        <Typography type="p" color="gray">
          {advertText}
        </Typography>
      </div>

      <div className={s.containerInfo}>
        <div className={s.item}>
          <Typography type="span" color="gray" size="f12" uppercase>
            Raise network:
          </Typography>
          <div>
            <img src={raiseItem.img} alt="icon" />
            <Typography type="span" color="white">
              {raiseItem.name}
            </Typography>
          </div>
        </div>
        <div className={s.item}>
          <Typography type="span" color="gray" size="f12" uppercase>
            Distribution network:
          </Typography>
          <div>
            <img src={distrItem.img} alt="icon" />
            <Typography type="span" color="white">
              {distrItem.name}
            </Typography>
          </div>
        </div>
        <div className={s.item}>
          <Typography type="span" color="gray" size="f12" uppercase>
            Target raise:
          </Typography>
          <div>
            <img src={targetItem.img} alt="icon" />
            <Typography type="span" color="white">
              {targetItem.name}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  </div>
);
