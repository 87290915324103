import { VFC } from 'react';

import { avatar } from 'assets/img/section';
import clsx from 'clsx';
import { Typography } from 'components/Typography';
import shortenPhrase from 'utils/shortenPhrase';
import s from './styles.module.scss';

export interface AccountParamsProps {
  address: string;
  className?: string;
  title?: string;
}

export const AccountParams: VFC<AccountParamsProps> = ({ address, title, className }) => {
  return (
    <div className={className}>
      <div className={clsx(s.address)}>
        <img src={avatar} alt="avatar" />
        <div>
          <Typography type="span" color="gray" size="f12" className={s.text} uppercase>
            {title || 'Connected'}
          </Typography>
          <Typography type="span" color="white">
            {shortenPhrase(address, 6, 4)}
          </Typography>
        </div>
      </div>
    </div>
  );
};
